li.categories-link{
    width: 145px;
    cursor: pointer;
    align-self: flex-start;
    display: inline-block;
    transform: scale(1);
    box-sizing: border-box;
    position: relative;
    a{
        color: $white;
    }
    span.title{
        margin-top: 20px;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 11pt;
        font-weight: 300;
        position: absolute;
        line-height: 1.5;
        @media only screen and (max-width: 992px) {
            font-size: 8pt;
            line-height: 1.3;
        }
    }
    .icon-container{
        display: block;
        width: 75px;
        height: 75px;
        margin: 0 auto;
        margin-top: 12px;
        background: $green;
        border-radius: 150px;
        box-shadow: 0 0 0 8px rgba(0,0,0,.15);
        transition: all $ease;
        position: relative;
        text-align: center;
        will-change: transform;
        transform: scale(1);
        @media only screen and (max-width: 992px) {
            width: 50px;
            height: 50px;
        }
        &:after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 25px;
            height: 25px;
            border-radius: 25px;
            transform-origin: center center;
            transform: translateX(-50%) translateY(-50%);
            border: 2px solid transparent;
            transition: all ease .25s;
            opacity: 0;
            //backface-visibility: hidden;
        }
        svg, .fa, img{
            width: 50%;
            height: 50%;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    &:hover, &.active{
        .icon-container{
            transform: scale(1.1);
            &:after{
                border: 2px solid $green;
                opacity: 0;
                width: 88px;
                height: 88px;
                opacity: 1;
                border-radius: 500px;
            }
            @each $name, $colour in $colours {
                &.background-#{$name}{
                    &:after {
                        border: 2px solid $colour;
                    }
                }
            }
        }
    }
}
