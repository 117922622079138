.sidebar-social-links{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    li{
        list-style-type: none;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        text-align: center;
        a{
            font-size: 25px;
            color: #6d6e71;
        }
    }
}