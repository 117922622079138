.search-input-group {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    &:last-of-type{
        margin-right: 0;
    }
    &.large{
        width: 74%;
    }
    &.small{
        width: 10.5%;
    }
    @media only screen and (max-width: 992px) {
        margin-right: 0;
        &.large{
            width: 100%;
            margin-bottom: 10px;
        }
        &.small{
            width: 45%;
            &:last-of-type{
                margin-left: 20px;
            }
            &.margin-right-0{
                float: right;
            }
        }
    }
}
