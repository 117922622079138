.story-overlay .story-inner {
    text-align: left;
    width: 100%;
    padding-bottom: 50px;
    color: $white;
    display: block;
    margin: 0 auto;
    position: relative;
    width: calc(100% - 200px);
    @media only screen and (max-width: 1300px) {
        width: 100%;
    }

    @media only screen and (max-width: 1024px) {
        height: 150%;
        overflow: scroll;
        .content{
            height: auto !important;
            overflow: scroll;
        }
    }
    @media only screen and (max-width: 992px) {
        //height: 200%;
    }

    .content {
        height: 100%;
        section {
            width: 50%;
            display: inline-block;
            float: left;
            height: 100%;
            @media only screen and (max-width: 992px) {
                width: 100%;
                float: none;
                height: auto;
                display: block;
                padding-left: 0;
                margin-bottom: 30px;
            }
        }
    }
}
