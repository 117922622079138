.story-card-share-button {
    .share-box {
        .share-link {
            display: block;
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;
            i.fab {
                font-size: 20px;
                transform: translateY(-1px);
                margin-right: 10px;

            }
            &:hover {
                color: $white;
            }
            &.fb {
                &:hover {
                    background: #3b5998;
                }
            }
            &.tw {
                &:hover {
                    background: #00aced;
                }
            }
            &.gp {
                &:hover {
                    background: #dd4b39;
                }
            }
        }
    }
}