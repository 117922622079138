.story-filter-no-results{
    text-align: center;
    margin-top:100px;
    .btn{
        display: block;
        margin:0 auto;
        width: 100%;
        font-size: 1.2rem;
        max-width: 250px;
    }
}