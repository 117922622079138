ul.time-bar{
    .blocks{
        display: inline-block;
        float: left;
        position: relative;

        @media only screen and (max-width: 768px) {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .blocks-percent{
        opacity: 0;
        width: calc(100% - 340px);
        position: absolute;
        top: 0;
        left: 190px;
        z-index: 999;
        display: flex;
        li{
            flex-grow:1;
        }
        @media only screen and (max-width: 1024px) {
            width: calc(100% - 100px);
            left: 50px;
        }
    }
}