#login-page{
    form{
        .note{
            a{
                color: $white;
                font-size: 14px;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}