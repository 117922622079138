#liked-stories{
    .stories-list{
        max-width: 750px;
        width: 95%;
        display: block;
        margin:0 auto;
        list-style: none;
        padding:0;
        margin-top:100px;
        max-height: 300px;
        overflow:hidden;
        overflow-x: hidden;
        li{
            list-style-type: none;
            font-size: 18px;
            font-weight: 400;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $white;
            &:last-of-type{
                border-bottom: 0;
            }

            .buttons{
                width: 100%;
                color: $white;
                a, .main-button{
                    color: white;
                    font-weight: normal;
                    position: relative;
                    padding-right: 25px;
                    margin-right: 15px;
                    height: 100%;
                    font-size: 10px;
                    &:after{
                        content:"";
                        position: absolute;
                        top:-7px;
                        right:0;
                        height: 25px;
                        width: 1px;
                        //border-left: 1px solid $white;
                    }
                    &:last-of-type{
                        &:after{
                            display: none;
                        }
                    }
                    &:Hover{
                        color:darken($white, 20%);
                        text-decoration: none;
                    }
                    &.liked{
                        color:#F44336;
                    }
                }
                .like{
                    margin-right: 0;
                    padding-right: 0;
                }
                i{
                    font-size: 2em;
                    display: inline-block;
                    vertical-align: middle;
                }
                span{
                    margin-left: 10px;
                    font-size: 11pt;
                }

                .share-button{
                    position: relative;
                    padding-right: 0;
                    display: inline-block;
                    .main-button {
                        margin-right: 0px;
                        cursor: pointer;
                        &:after{
                            top: 2px;
                        }
                        &:hover {
                            color: darken($white, 20%);
                        }
                    }
                    .share-box{
                        position: absolute;
                        top: calc(100% + 15px);
                        right: 15px;
                        width: 175px;
                        text-align: left;
                        height: auto;
                        background:$white;
                        border-radius: 5px;
                        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                        padding-top:10px;
                        padding-bottom: 10px;
                        transform: scale(0);
                        transform-origin: top right;
                        transition: all ease 0.15s;
                        z-index: 99;
                        &.open{
                            transform: scale(1);
                        }
                        .share-link{
                            display: block;
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            padding-left: 20px;
                            padding-right:20px;
                            cursor: pointer;
                            color: $grey;
                            i.fab{
                                font-size: 20px;
                                transform: translateY(-1px);
                                margin-right: 10px;

                            }
                            &:hover{
                                color: $white;
                            }
                            &.fb{
                                &:hover{
                                    background: #3b5998;
                                }
                            }
                            &.tw{
                                &:hover{
                                    background: #00aced;
                                }
                            }
                            &.gp{
                                &:hover{
                                    background: #dd4b39;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}