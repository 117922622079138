.divider{
    text-align: center;
    color: $white;
    display: block;
    position: relative;
    &:before,&:after{
        content:"";
        position: absolute;
        top: 22%;
        left: 0;
        width: 45%;
        height: 1px;
        background: $white;
        display: block;
    }
    &:after{
        left: auto;
        right: 0;
    }
}