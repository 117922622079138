.related-stories{
    width: 50%;
    display: inline-block;
    float: left;
    ul.related{
        box-sizing: border-box;
        list-style-type: none;
        padding-left: 0;
        li{
            width: 100%;
            height: auto;
            display: block;
            line-height: 1.4;
            border-bottom: 1px solid $white;
            padding-bottom: 10px;
            margin-bottom: 10px;
            a{
                color:$green;
                &:Hover{
                    text-decoration: underline;
                }
            }
            span{
                font-weight: 400;
                display: block;
                &.related-desc{
                    color:$white;
                }
            }
            &:last-of-type{
                border-bottom: 0;
            }
        }
    }
}