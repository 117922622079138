.reponsive-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $black;
    z-index: 99999999999;
    display: none;

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;

        h4 {
            margin-top: 30px;
            color: $white;
        }
    }
}

@media only screen and (orientation: portrait) {
    .reponsive-overlay {
        display: block;
    }
}


.mobile-categories {
    display: none;
    position: fixed;
    top: 100%;
    left: 0;
    height: auto;
    padding-bottom: 50px;
    width: 100%;
    background: lighten($grey, 50%);
    padding-top: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    transition: all ease 0.25s;
    transform: translateY(0);
    z-index: 999;
    @media only screen and (max-width: 992px) {
        display: block !important;
        .categories-navigation {
            display: block !important;

            ul:before {
                display: none;
            }
        }
    }

    &.open {
        transform: translateY(-100%);
    }

    li.categories-link a {
        color: $black;
    }

    .toggle-mobile-categories {
        position: absolute;
        bottom: 100%;
        padding-top: 2px;
        width: 200px;
        right: 50px;
        border-radius: 100% 100% 0 0;
        background: lighten($grey, 50%);
        color: $black;
        height: 50px;
        line-height: 45px;
        font-size: 50px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 0;
    }
}


@media only screen and (max-width: 600px) {
    .overlay {
        width: 100%;
        height: 100%;
    }
    body {
        zoom: .8;

        .main-body {
            height: 100%;
        }

        .story-overlay .story-inner .content {
            height: unset;
            overflow: unset;
        }

        .pace:after {
            height: 100%;
            width: 100%;
        }
    }
}

@media only screen and (min-width: 800px) {
    body {
        zoom: .9;
    }
}

@media only screen and (min-width: 1000px) {
    body {
        zoom: 1;
    }
}

.pagination {
    .page-link {
        color: $white;
        margin-left: 0;
    }

    .page-item.active{
        border-color: $green;
    }

    .page-item.disabled .page-link {
        color: $brown-grey;
    }

    .page-item:first-child .page-link {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .page-item.active .page-link{
        background-color: $green;
        border-color: $green;
    }
}



#select-a-story .pagination li a{
    text-align: center;
    padding-left: 0;
    padding-right: 0 !important;
}
