.story.card.search-card {
    min-height: 450px;
    position: relative;
    cursor: pointer;
    .mag {
        width: 200px;
        height: 200px;
        border-radius: 200px;
        position: absolute;
        top: 100px;
        left: 75px;
        line-height: 250px;
        text-align: center;
        background: rgba($black, 0.3);
        .fa {
            font-size: 100px;
        }
    }

    .search-link {
        background: $white;
        width: 70%;
        display: block;
        padding: 15px;
        position: absolute;
        top: 350px;
        left: 15%;
        text-align: center;
        border-radius: 20px;
    }

}