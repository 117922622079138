.year-slider{
    ul{
        li{
            font-size: 75px;
            font-weight: 500;
            width: 350px;
            height: 120px;
            opacity: 0.1;
            animation: 2s ease 0s normal forwards 1 fadein;
            .label.text{
                //overflow: hidden;
                transform:translate(-50%, -50%);
                display:inline-block;
                clear:both;
                float:left;
                white-space:nowrap;
                text-align:center;
                padding:0;
                left:50%;
                top:50%;
                position:relative;
            }
            @media only screen and (max-width: 992px) {
                width: 250px;
            }
        }
    }
}

@keyframes fadein{
    0% { opacity:0.1; }
    66% { opacity:0.1; }
    100% { opacity:1; }
}
