.card-slider {
    @each $name, $colour in $colours {
        &.colour-#{$name} {
            .flipster__button svg {
                stroke: $colour;
            }
            .flipster__button:hover,
            .flipster__button:focus {
                svg {
                    stroke: $colour;
                }
            }
        }
    }
}

.main-content{
    #slider{
        @media only screen and (max-width: 992px) {
            display: block;
            overflow-x: scroll;
            white-space: nowrap;
            li{
                margin-left: 10px;
                margin-right: 10px;
                display: inline-block;
                &:first-of-type{
                    margin-left: 0;
                }
            }
        }
    }
}

.flipster{
    ul{
        list-style-type: none !important;
    }
}

.year-title{
    display: none;
    @media only screen and (max-width: 992px) {
        display: block;
        font-size: 24px;
        max-width: 100%;
    }
}
