.story.card.daily-card{
    .story-card-image{
        background-size: cover;
        background-image: url($background-image);
    }
    .date{
        position: absolute;
        top:calc(50% + 25px);
        left: 50%;
        width: 100%;
        color: $white;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);
        font-size: 50px;
        line-height: 75px;
        span{
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: -25px;
            margin-top: -25px;
        }
    }
    text-align: left !important;
}