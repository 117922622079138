form .form-group {
  select::-ms-expand {
    display: none;
  }
  label {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;

    &.error {
      color: red;
    }
  }
  .form-control {
    margin-bottom: 0;
  }
  input.form-control,
  textarea.form-control,
  select.form-control {
    font-size: 16px;
    line-height: 24px;
    padding: 6px 12px;
    height: 38px;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid $grey;
    border-radius: 5px;
    box-sizing: border-box;
    max-width: 100%;
    &:focus {
      border: 1px solid $green;
      box-shadow: 0 1px 0 0 $green;
    }
    &.error {
      border-color: red;
    }
  }
  textarea.form-control {
    height: auto;
  }
  select.form-control {
    padding-right: 20px;
    position: relative;
    z-index: 1;
    padding-top: 2px;
  }
  .select-anchor {
    position: relative;
    &:after {
      position: absolute;
      top: 6px;
      right: 10px;
      font-family: "Font Awesome 5 Pro";
      content: "\f107";
      color: black;
    }
  }
  &.radio-wrap {
    display: flex;
    @include breakpoint(500px) {
      flex-direction: column;
    }
    .options {
      display: flex;
    }
    > label {
      margin-right: 15px;
      @include breakpoint(500px) {
        flex: 0 0 100%;
      }
    }
    .form-check {
      padding-top: 5px;
      padding-left: 40px;
      margin-right: 15px;
      position: relative;
      @include breakpoint(500px) {
        flex: 0 0 50%;
      }
      label {
        margin-top: 2px;
      }
      input {
        display: none;
      }
      .disc {
        position: absolute;
        border: 1px solid $grey;
        border-radius: 50%;
        top: 10px;
        left: 0;
        width: 25px;
        padding: 5px;
        height: 25px;
      }
      input:checked + .disc {
        span {
          background-color: $green;
          border-radius: 50%;
          width: 100%;
          height: 100%;
			display:block;
        }
      }
    }
  }
    &.half{
        .btn{
            width: 49%;
            &:last-of-type{
                float: right;
                margin-right: 0;
            }
        }
    }
}

input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime-local], input[type=tel], input[type=number], input[type=search-md], input[type=search], textarea.textarea{
    &.white-input {
        width: calc(100% - 20px);
        color: $white;
        border: 2px solid $white !important;
        box-shadow: none !important;
        border-radius: 3px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 300;
        background: transparent;
        height: auto;
        @include placeholder {
            color: $white;
        }
        &:focus {
            border: 2px solid $white;
            box-shadow: none !important;
            background: $white;
            color: $brown-grey;
            @include placeholder {
                color: $brown-grey;
            }
        }
    }
}


.custom-check {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-weight: normal;
        span{
            display: inline-block;
            float: left;
            width:calc(100% - 25px);
            //padding-left: 15px;
        }
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 15px;
        height: 15px;
        background: transparent;
        border:2px solid $white;
        border-radius: 3px;
        transform: translateY(5px);
        float: left;
    }

    // Box hover
    &:hover + label:before {
        background: rgba($white, 0.3);
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: $white;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: transparent;
        width: 2px;
        height: 2px;
        transform: rotate(45deg);
    }
}


input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime-local], input[type=tel], input[type=number], input[type=search-md], input[type=search], textarea.textarea{
    background:$white;
    border-radius: 5px;
    border-bottom: 0;
    box-shadow: none !important;
    padding: 5px 15px;
    width: calc(100% - 30px);
    outline: none !important;
    color: $grey !important;
    &:focus{
        box-shadow: none !important;
        border-bottom: 0;
    }
}

textarea.textarea{
    height: 100px;
    resize:none;
    padding: 10px 15px;
    width: 100%;
    border: 0;
}