.sidebar-toggle-button{
    background-image: url("../images/sidebar/toggle-button.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 65px;
    height: 70px;
    position: absolute;
    top:50%;
    left: 265px;
    color:$white;
    transform: translateY(-50%);
    cursor: pointer;
    .fa{
        position: absolute;
        right: 10px;
        top:50%;
        transform: translateY(-50%);
        font-size: 1.2rem;
        transition: all $ease;
    }
    &.open{
        .fa{
            transform: translateY(-50%) rotate(-180deg);
        }
    }
}