.sidebar-company-logos {
    margin-bottom: 0;
    li{
        list-style-type: none;
        text-align: center;
        width: 49%;
        display: inline-block;
        vertical-align: center;
        img{
            max-height: 25px;
        }
    }
}