.indicator{
    display: block;
    position: absolute;
    left:0px;
    top:3px;
    width:10px;
    height: 20px;
    transition:all linear 0.2s;
    z-index: 99;
    cursor: ew-resize;
    &:before{
        content:"";
        border-style: solid;
        border-width: 7px 10px 7px 0;
        transform-origin: center center;
        transform: scale(1) rotate(90deg);
        border-color: transparent $white transparent transparent;
        position: absolute;
        left: calc(50% - 5px);
        top:15px;
        z-index:999;
        transition:all ease 0.25s;
    }
    &:after{
        content: "\f053        \f054";
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        display: block;
        width: 50px;
        height: 25px;
        background: $white;
        position: absolute;
        top:27px;
        z-index:99;
        left: -20px;
        color:$green;
        text-align: center;
        font-size: 12pt;
        line-height: 27px;
        box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.09);
        transition:all ease 0.25s;
    }
    @media only screen and (max-width: 992px) {
        display: none;
    }
}
