.quiz.card {
    width: 350px;
    background: $white;
    border-radius: 1.2em;
    overflow: hidden;
    display: inline-block;
    margin: 0 auto;
    vertical-align: top;
    position: relative;
    max-height: 500px;
    min-height: 420px;
    .content{
        height: auto;
    }
    &.incorrect, &.correct{
        .content{
            height: 0;
            overflow: hidden;
        }
    }
    &.incorrect{
        .quiz-card-incorrect-overlay{
            height: auto;
        }
    }
    &.correct{
        .quiz-card-correct-overlay{
            height: auto;
        }
    }
}