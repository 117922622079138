@each $name, $hex in $colours {
    .text-#{$name},
    .text-#{$name} *,
    .text-#{$name} a {
        color: $hex;
    }
    .bg-#{$name} {
        background-color: $hex !important;
    }
}

body,
html {
    overflow-x: hidden;
    max-width: 100%;
}

.divider {
    padding-bottom: 36px;
}

.has-blur {
    &.is-blurred {
        filter: blur(6px);
        pointer-events: none;
    }
}

.background-image {
    position: fixed;
    left: -10px;
    top: -10px;
    right: 0;
    z-index: -1;
    background: url($background-image);
    background-size: cover;
    background-attachment: fixed;
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    user-select: none;
}

.margin-right-0 {
    margin-right: 0 !important;
}

.alert-danger {
    display: none !important;
}
