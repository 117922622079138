form.create-story{
    button.btn{
        background: $green;
        padding: 12px 20px;
        font-size: 18px;
        color:$white;
        margin-top:30px;
    }
}

.edit-timeline{
    .story-placeholder{
        background:rgba(255,255,255,0.2);
        border:1px dashed rgba(255,255,255,0.4);
        height:64px;
        margin-top:1.5rem;
        margin-bottom:1.5rem;
    }
    .stories{
        position:relative;
    }
    .story{
        border-bottom:1px solid rgba(255,255,255,0.4);
        padding:1.5rem 0;
    }
    .media-body{
        h5{
            .fas{
                float:right;
                cursor: grab;
                &:active{
                    cursor:grabbing;
                }
            }
        }
    }
}