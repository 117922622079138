.story-overlay-information {
    .para {
        max-height: 245px;
        overflow: auto;
        margin-bottom: 25px;

        @each $name, $colour in $colours {
            &.colour-#{$name}{
                .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                    background-color: $colour;
                }
                .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
                    background-color: lighten($colour, 10);
                }
            }
        }
        @media only screen and (max-width: 960px) {
            max-height: unset;
        }
    }
}
