.story-card-image{
    height: 225px;
    width: 100%;
    background: $light-grey;
    border-bottom: 7px solid $green;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: block;
    img{
        height: auto;
        min-height: calc(100% + 3px);
        max-height: 120%;
        min-width: 100%;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    @media only screen and (max-width: 992px) {
        height: 175px;
    }
}

.story.card {
    @each $name, $colour in $colours {
        &.colour-#{$name} {
            .story-card-image {
                border-color: $colour;
            }
        }
    }
}
