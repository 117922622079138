.search-matrix-container{
    width: calc((100% / 5) - 20px);
    max-height: 230px;
    min-height: 230px;
    overflow: hidden;
    display: inline-block;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-of-type(5n+5){
        margin-right: 0;
    }
    h5{
        margin-bottom: 10px;
        font-size: 18px;
    }
    label{
        margin-bottom: 0;
    }
    ul{
        max-height: 180px;
        min-height: 180px;
        overflow: scroll;
        list-style-type: none;
        padding-left: 20px;
        overflow: hidden;
    }
    @media only screen and (max-width: 992px) {
        width: calc((100% / 2) - 20px);
    }
}
