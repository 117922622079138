.quiz-card-correct-overlay{
    height: 0;
    overflow: hidden;

    background:$white;
    .header{
        background:$green;
        padding:20px;
        color:$white;
        i.fa{
            font-size: 75px;
            display: inline-block;
            vertical-align: bottom;
        }
        h3{
            display: inline-block;
            color:$white;
        }
    }
    .card-content{
        color: $grey;
        padding: 20px;
        p{
            border-bottom: 2px solid $grey;
            padding-bottom: 5px;
            margin-bottom: 5px;
            &:first-of-type{
                border: 0;
            }
            &:last-of-type{
                border: 0;
            }
        }
    }
}