#create-an-account-page{
    text-align: center;
    .row{
        max-width: 900px;
        max-width: 900px;
        margin: 0 auto;
    }


    .box{
        border-radius: 10px;
        border: 2px solid $white;
        padding: 30px;
        text-align: left;
        .btn{
            display: block;
            max-width: 250px;
            margin:0 auto;
        }
    }

}