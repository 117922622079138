#welcome-page{
    .content{
        max-width: 650px;
        display: block;
        margin:0 auto;
        width: 95%;
        .btn{
            width: 200px;
        }
    }
}