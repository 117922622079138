.close-overlay, .close-overlay-button{
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 4px;
    font-size: 38px;
    color: $white;
    z-index:999999999999;
    @media only screen and (max-width: 992px) {
        //top: -30px;
        right:20px;
    }
}
