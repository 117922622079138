.story-card-share-button{
    position: relative;
    padding-right: 0;
    display: inline-block;
    .main-button {
        cursor: pointer;
        &:after{
            top: 5px;
        }
        &:hover {
            color: darken($grey, 20%);
        }
    }
}