.year-slider{
    width: 350px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    @media only screen and (max-width: 992px) {
        display: none;
    }
    span.cat-title{
        position: absolute;
        top: 105px;
        width: 100%;
        display: block;
        left: 0;
        font-size: 18pt;
        font-weight: 500;
    }
    @each $name, $colour in $colours {
        &.colour-#{$name}{
            a{
                color: $colour;
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        top: 35px;
    }
}
