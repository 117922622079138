ul.time-bar{
    li{
        cursor: pointer;
        width: 18px;
        height: 5px;
        background: $green;
        display: inline-block;
        float: left;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        position: relative;
        @media only screen and (max-width: 992px) {
            width: calc(1.5vw);
        }
        &:first-of-type{
            margin-left: 0px;
            height: 30px;
            width: 5px;
            margin-top: -3px;
        }
        &:last-of-type{
            margin-right:0px;
            height: 30px;
            margin-top: -3px;
            width: 5px;
        }
    }
}
