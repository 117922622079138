.related-links{
    width: calc(50% - 30px);
    margin-left: 30px;
    display: inline-block;
    &:after{
        content: "";
        display: table;
        clear: both;
    }
    ul{
        list-style-type: none;
        padding-left: 0;
        li{
            width: 100%;
            height: auto;
            display: block;
            line-height: 1.4;
            padding-bottom: 2px;
            a{
                color: $white;
                font-weight: 400;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}