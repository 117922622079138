.navigation-secondary{
    display: block;
    width: 100%;
    padding-right: 50px;
    text-align: right;
    padding-top:20px;
    a.search-link{
        font-size: 2rem;
        color: $white;
        @media only screen and (max-width: 992px) {
            font-size: 1.5rem;
        }
    }
    @media only screen and (max-width: 992px) {
        display: inline-block;
        float: right;
        padding-right: 20px;
        padding-top: 10px;
        width: auto;
    }
}
