.search-matrix-container {
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: $grey;
    }

    .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
        background-color: lighten($grey, 10);
    }

    .mCSB_scrollTools .mCSB_draggerRail, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        border-radius: 0;
    }

    .mCSB_scrollTools .mCSB_draggerRail {
        width: 8px;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 4px;
    }
}