ul.time-bar{
    span.first-year, span.last-year{
        text-align: center;
        width: 100px;
        display: inline-block;
        float: left;
        height: 25px;
        font-size: 15pt;
        font-weight: 500;
        line-height: 23px;
        margin-right: 20px;
        // 		margin-top:-7px;
        position: relative;
        & > span{
            position: absolute;
            left: 0;
            top:50%;
            transform: translateY(-50%);
        }
        @media only screen and (max-width: 1024px) {
            display: none;
        }
    }
    span.last-year{
        margin-left: 20px;
        margin-right: 0;
    }
}