.story-card-share-button {
    .share-box {
        position: absolute;
        bottom: calc(100% + 15px);
        left: 0;
        width: 150px;
        height: auto;
        background: $white;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        padding-top: 10px;
        padding-bottom: 10px;
        transform: scale(0);
        transform-origin: bottom left;
        transition: all ease 0.15s;
        &.open {
            transform: scale(1);
        }
    }
}