.quiz-card-question{
    padding:20px;
    border-bottom: 7px solid $green;
    h3{
        color:$green;
        margin-bottom: 1rem;
    }
    p{
        color:$grey;
        margin-bottom: 0;
    }
}