#select-a-story {
    .main-content {
        margin-top: 10px;
    }

    .categories-sidebar {
        @media only screen and (max-width: 1024px) {
            h3 {
                font-size: 18px;
            }
        }
        ul {
            list-style-type: none;
            padding-left: 0px;
            li.category-item {
                list-style-type: none;
                display: block;
                width: 100%;
                label {
                    margin-bottom: 0;
                    span{
                        a{
                            color: $white;
                        }
                    }
                }
                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }
            }
        }
    }

    .no-results-text{
        display: none;
    }

    .story-card-mini {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 20px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        .image-container {
            width: 100%;
            height: 200px;
            position: relative;
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            background: $white;
            img {
                width: calc(100% + 2px);
                max-width: calc(100% + 2px);
                height: auto;
                position: absolute;
                top: calc(50% - 1px);
                left: calc(50% + 1px);
                transform: translateY(-50%) translateX(-50%);
            }

            &:before, &:after{
                transition: all ease 0.2s;
            }

            &:after{
                opacity: 0;
                z-index:3;
                content: "+";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                color: $white;
                font-size: 50px;
                font-weight: bold;
                transform-origin: top left;
            }
            &:before{
                opacity: 0;
                z-index:2;
                border-radius: 10px 10px 0 0;
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: calc(100%);
                height: calc(100%);
                background: rgba($green, 0.75);
            }
        }
        .text-container {
            color: $grey;
            font-size: 18px;
            font-weight: 400;
            background: $white;
            border-radius: 0 0 10px 10px;
            padding: 10px;
            margin-top: -2px;
        }

        &:hover{
            .image-container{
                &:before, &:after{
                    opacity: 1;
                }
            }
        }
        &.processing{
            .image-container{
                &:before{
                    opacity:1;
                }
                &:after{
                    opacity: 1;
                    content: "\f3f4";
                    font-family: "Font Awesome 5 Pro";
                    animation: rotate 1s infinite linear;
                }
            }
        }

    }

    @each $name, $colour in $colours {
        .story-card-mini.colour-#{$name} {
            .text-container {
                color: $colour;
            }
        }
    }


    .pagination{
        display: block;
        width: 100%;
        text-align: center;
        margin-top:50px;
        margin-bottom: 50px;
        li{
            display: inline-block;
            width: 35px;
            height: 35px;
            border-radius: 2px;
            background: transparent;
            border: 2px solid $white;
            color: $white;
            text-align: center;
            line-height: 32px;
            &.active, &.disabled{
                cursor: not-allowed;
                &:hover{
                    background: transparent;
                    color: $white;
                }
            }
            &:hover{
                background: $white;
                color: $grey;
                a{
                    color: $grey;
                }
            }
            &.active{
                background: $white;
                color: $grey;
                &:hover{
                    background: $white;
                    color: $grey;
                }
            }
            a{
                height: 100%;
                width: 100%;
                display: block;
                &:hover{
                    color: $green;
                }
            }
        }
    }


}

@keyframes rotate{
    from {transform:rotate(0deg) translate(-50%, -50%);}
    to {transform:rotate(360deg) translate(-50%, -50%);}
}

