.bx-controls .bx-controls-direction .bx-next, .bx-controls .bx-controls-direction .bx-prev{
    display: block;
}

.bx-wrapper .bx-controls-direction a{
    position: absolute;
    top: 50%;
    margin-top: -20px;
    outline: 0;
    width: 32px;
    height: 32px;
    z-index: 9999;
    font-size: 30pt;
}

.bx-wrapper .bx-next{
    right: -36px;
}

.bx-wrapper .bx-prev{
    left: -36px;
}