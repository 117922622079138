#premium-holding{
    .main-content{
        margin-top: 0;
    }

    .title{
        text-align: center;
        margin-bottom: 100px;
    }

    .content{
        max-width: 900px;
        width: 90%;
        display: block;
        margin: 0 auto;
        h1, h2, h3, h4, h5, h6{
            color: $pink;
        }
    }
}