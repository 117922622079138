/* Theme Brand Colours */
/* EM Converter */
/* Breakpoints */
/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Rubik:100,100i,200,200i,300,300i,400,400i,500,500i,700,700i");
/* Breakpoint mixin */
@media only screen and (max-width: 1200px) {
  ul.time-bar span.first-year, ul.time-bar span.last-year {
    font-size: 10pt;
    line-height: 16px;
    width: 50px;
  }
  ul.time-bar .blocks-percent {
    left: 100px;
  }
  ul.time-bar li {
    width: 12px;
  }
}

@media only screen and (max-width: 1300px) {
  .flipster .flipster__button {
    background: #FFFFFF;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .flipster .flipster__button:hover {
    background: #FFFFFF !important;
  }
}

@media only screen and (max-width: 1024px) {
  .login-container .inner {
    width: calc(100% - 200px);
  }
  ul.time-nav li.active {
    transform: scale(1);
  }
}

@media only screen and (max-width: 900px) {
  header .nav .inner .year-slider {
    width: 300px;
  }
}

@media only screen and (max-width: 880px) {
  .story-overlay .story-inner .content section {
    display: block;
    width: 100%;
    float: none;
  }
  .story-overlay .story-inner .content section.resources {
    padding-left: 0;
  }
  .login-overlay .login-inner .sections .left-section, .login-overlay .login-inner .sections .right-section {
    display: block;
    width: 100%;
    float: none;
  }
  .login-overlay .login-inner .sections .left-section p, .login-overlay .login-inner .sections .left-section ul,
  .login-overlay .login-inner .sections .left-section .list-of-features,
  .login-overlay .login-inner .sections .right-section form input {
    width: 100%;
  }
  header form .tags-container {
    width: calc((100%/3) - 20px);
  }
  header form .tags-container:nth-of-type(5n+5) {
    margin-right: 20px;
  }
  header form .tags-container:nth-of-type(3n+3) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 850px) {
  ul.time-bar span.first-year, ul.time-bar span.last-year {
    display: none;
  }
  ul.time-bar .blocks-percent {
    left: 0;
    width: 100%;
  }
}

.reponsive-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000;
  z-index: 99999999999;
  display: none;
}

.reponsive-overlay .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

.reponsive-overlay .content h4 {
  margin-top: 30px;
  color: #FFFFFF;
}

.mobile-time-nav {
  display: none;
}

@media only screen and (max-width: 992px) {
  ul.time-nav, ul.time-bar, header aside.sidemenu, header .close-menu, .login-container {
    display: none;
  }
  header .nav .inner .year-slider span.cat-title {
    top: 95px;
    font-size: 12pt;
  }
  body.page-template-home .explore-container .text h1 {
    font-size: 30px;
    line-height: 28px;
  }
  body.page-template-home .explore-container .text .button {
    padding: 10px;
  }
  body.page-template-home .explore-container .card-container .card {
    width: 280px;
  }
  body.page-template-home .explore-container .text {
    width: 45%;
  }
  body.blur header {
    width: 0;
  }
  .mobile-time-nav {
    display: block;
    background: #eaebeb;
    width: 100%;
    word-wrap: normal;
    position: fixed;
    bottom: 0;
    left: 0;
    padding-top: 35px;
    transform: translateY(100%);
    transition: all ease 0.5s;
    z-index: 9999999;
  }
  .mobile-time-nav .nav-container {
    width: 100%;
    overflow-y: auto;
  }
  .mobile-time-nav.open {
    transform: translateY(0);
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16), 0 -3px 6px rgba(0, 0, 0, 0.23);
  }
  .mobile-time-nav .mobile-time-toggle {
    position: absolute;
    bottom: 100%;
    background: #eaebeb;
    right: 50px;
    width: 140px;
    height: 31px;
    text-align: center;
    border-radius: 100% 100% 0 0;
  }
  .mobile-time-nav ul.time-nav {
    display: flex;
    width: 150%;
    height: 135px;
    margin-top: 15px;
  }
  .mobile-time-nav ul.time-nav li {
    z-index: 2;
  }
  .mobile-time-nav ul.time-nav li span {
    color: #76787a;
  }
  .mobile-time-nav ul.time-nav:after {
    z-index: 1;
  }
  header .nav .inner .year-slider {
    width: 200px;
  }
  header {
    margin-top: 0;
  }
  header .reversed-logo {
    width: 100px;
    top: 10px;
    left: 10px;
  }
  body article {
    width: 100%;
  }
  header .nav .inner {
    width: calc(100% - 20px);
  }
  header .nav .inner .nav-button.mobile-only {
    display: inline-block;
    float: right;
    magin-right: 40px;
  }
  .story-overlay .story-inner, .search-overlay .search-inner {
    width: calc(100% - 50px);
    padding-top: 30px;
  }
  .story-overlay .story-inner .main-body, .search-overlay .search-inner .main-body {
    width: 100%;
    margin-left: 0;
  }
  .story-overlay .story-inner .close, .search-overlay .close-search {
    top: 0;
    right: -10px;
  }
  .flipster__button {
    background: #FFFFFF;
    border-radius: 10px 0 0 10px;
    padding-left: 5px;
    padding-top: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .flipster__button.flipster__button--prev {
    border-radius: 0 10px 10px 0;
    padding-left: 0;
    padding-right: 5px;
  }
  .flipster__button:focus, .flipster__button:hover {
    background: #FFFFFF;
  }
  header .nav {
    margin-bottom: 35px;
  }
  footer#footer {
    display: block;
  }
  .card {
    width: 300px;
  }
  .card.daily-card .card-image {
    height: 200px;
  }
  .card.search-card .mag {
    left: 50px;
  }
  .card .card-image {
    height: 150px;
  }
  .card .card-content h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .card .card-content p {
    font-size: 14px;
    line-height: 1.4;
  }
  header .nav .inner .nav-button.search.refine {
    margin-top: 10px;
  }
  header form .tags-container h5 {
    font-size: 14px;
  }
  .custom-check + label span {
    font-size: 13px;
  }
  header form .tags-container {
    width: calc((100%/2) - 20px);
  }
  header form .tags-container:nth-of-type(3n+3) {
    margin-right: 20px;
  }
  header form .tags-container:nth-of-type(2n+2) {
    margin-right: 20px;
  }
}

@media only screen and (orientation: portrait) {
  header {
    display: none;
  }
  body article {
    display: none;
  }
  .reponsive-overlay {
    display: block;
  }
  header .nav .inner .nav-button.search.refine {
    margin-top: 10px;
  }
}

@-moz-document url-prefix() {
  header aside.sidemenu {
    left: -200px;
  }
  header .reversed-logo {
    top: 10px;
  }
  .login-container {
    z-index: 999999;
  }
  @media (min-width: 1300px) {
    body.menu-open {
      padding-left: 200px;
    }
  }
}

.flipster {
  overflow-y: hidden;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ul.time-nav:after {
    width: calc(100% - 85px);
  }
  article.menu-open .flipster__button {
    z-index: 0;
  }
}

body.blur .login-container {
  z-index: 2;
}

.text-dark-pink,
.text-dark-pink *,
.text-dark-pink a {
  color: #b83176;
}

.bg-dark-pink {
  background-color: #b83176 !important;
}

.text-pink,
.text-pink *,
.text-pink a {
  color: #e95197;
}

.bg-pink {
  background-color: #e95197 !important;
}

.text-orange,
.text-orange *,
.text-orange a {
  color: #ec6b1f;
}

.bg-orange {
  background-color: #ec6b1f !important;
}

.text-navy,
.text-navy *,
.text-navy a {
  color: #2e428c;
}

.bg-navy {
  background-color: #2e428c !important;
}

.text-cyan,
.text-cyan *,
.text-cyan a {
  color: #3ebee5;
}

.bg-cyan {
  background-color: #3ebee5 !important;
}

.text-purple,
.text-purple *,
.text-purple a {
  color: #af2785;
}

.bg-purple {
  background-color: #af2785 !important;
}

.text-off-white,
.text-off-white *,
.text-off-white a {
  color: #eee;
}

.bg-off-white {
  background-color: #eee !important;
}

.text-white,
.text-white *,
.text-white a {
  color: #fff;
}

.bg-white {
  background-color: #fff !important;
}

.text-grey,
.text-grey *,
.text-grey a {
  color: #777;
}

.bg-grey {
  background-color: #777 !important;
}

.text-light-grey,
.text-light-grey *,
.text-light-grey a {
  color: #a2a2a2;
}

.bg-light-grey {
  background-color: #a2a2a2 !important;
}

.text-off-grey,
.text-off-grey *,
.text-off-grey a {
  color: #f6f6f6;
}

.bg-off-grey {
  background-color: #f6f6f6 !important;
}

.text-green,
.text-green *,
.text-green a {
  color: #3ab18e;
}

.bg-green {
  background-color: #3ab18e !important;
}

.text-off-black,
.text-off-black *,
.text-off-black a {
  color: #282828;
}

.bg-off-black {
  background-color: #282828 !important;
}

.text-cream,
.text-cream *,
.text-cream a {
  color: #d4c3a3;
}

.bg-cream {
  background-color: #d4c3a3 !important;
}

body,
html {
  overflow-x: hidden;
  max-width: 100%;
}

.divider {
  padding-bottom: 36px;
}

.has-blur.is-blurred {
  filter: blur(6px);
  pointer-events: none;
}

.background-image {
  position: fixed;
  left: -10px;
  top: -10px;
  right: 0;
  z-index: -1;
  background: url("../images/background.png");
  background-size: cover;
  background-attachment: fixed;
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  user-select: none;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.alert-danger {
  display: none !important;
}

body {
  font-size: 16px;
  color: #76787a;
  line-height: 1.8em;
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 50px;
  line-height: 48px;
  font-weight: 500;
}

h2 {
  font-size: 32px;
  line-height: 31px;
  font-weight: 500;
}

h3 {
  font-size: 26px;
  line-height: 35px;
  font-weight: 500;
}

h4 {
  font-size: 24px;
  line-height: 35px;
  font-weight: bold;
}

h5 {
  font-size: 20px;
  line-height: 35px;
  font-weight: bold;
}

h6 {
  font-size: 16px;
  line-height: 35px;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 32px;
    line-height: 34px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 28px;
  }
}

p {
  margin-bottom: 15px;
}

a {
  color: #77b957;
}

a:hover {
  text-decoration: none;
}

strong, b {
  font-weight: bold;
}

.disable-external-link-icon {
  padding-right: 0 !important;
}

.disable-external-link-icon:after {
  display: none !important;
}

.pagination a {
  padding-right: 0 !important;
}

.pagination a:after {
  display: none !important;
}

.btn {
  font-weight: bold;
  padding: 12px 10px;
  font-size: 18px;
  line-height: 18px;
  text-transform: none;
  text-decoration: none !important;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 10px;
  box-shadow: none;
  color: #76787a;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.btn.clear {
  border-radius: 3px;
  border: 2px solid #FFFFFF;
  background: transparent;
  padding: 9px 10px;
}

.btn.clear:hover {
  background: #FFFFFF;
  color: #6D6E71 !important;
}

.btn.full-width {
  width: 100%;
}

.btn.btn-green {
  background: #77b957;
  width: auto;
  padding: 12px 20px;
  font-size: 16px;
}

.btn-panel {
  border-radius: 30px;
  white-space: normal;
  line-height: 1.2em;
  padding: 12px 15px;
}

.btn-panel p {
  font-weight: normal;
}

.btn-block {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

form .form-group select::-ms-expand {
  display: none;
}

form .form-group label {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
}

form .form-group label.error {
  color: red;
}

form .form-group .form-control {
  margin-bottom: 0;
}

form .form-group input.form-control,
form .form-group textarea.form-control,
form .form-group select.form-control {
  font-size: 16px;
  line-height: 24px;
  padding: 6px 12px;
  height: 38px;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #76787a;
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 100%;
}

form .form-group input.form-control:focus,
form .form-group textarea.form-control:focus,
form .form-group select.form-control:focus {
  border: 1px solid #77b957;
  box-shadow: 0 1px 0 0 #77b957;
}

form .form-group input.form-control.error,
form .form-group textarea.form-control.error,
form .form-group select.form-control.error {
  border-color: red;
}

form .form-group textarea.form-control {
  height: auto;
}

form .form-group select.form-control {
  padding-right: 20px;
  position: relative;
  z-index: 1;
  padding-top: 2px;
}

form .form-group .select-anchor {
  position: relative;
}

form .form-group .select-anchor:after {
  position: absolute;
  top: 6px;
  right: 10px;
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
  color: black;
}

form .form-group.radio-wrap {
  display: flex;
}

@media screen and (max-width: 500px) {
  form .form-group.radio-wrap {
    flex-direction: column;
  }
}

form .form-group.radio-wrap .options {
  display: flex;
}

form .form-group.radio-wrap > label {
  margin-right: 15px;
}

@media screen and (max-width: 500px) {
  form .form-group.radio-wrap > label {
    flex: 0 0 100%;
  }
}

form .form-group.radio-wrap .form-check {
  padding-top: 5px;
  padding-left: 40px;
  margin-right: 15px;
  position: relative;
}

@media screen and (max-width: 500px) {
  form .form-group.radio-wrap .form-check {
    flex: 0 0 50%;
  }
}

form .form-group.radio-wrap .form-check label {
  margin-top: 2px;
}

form .form-group.radio-wrap .form-check input {
  display: none;
}

form .form-group.radio-wrap .form-check .disc {
  position: absolute;
  border: 1px solid #76787a;
  border-radius: 50%;
  top: 10px;
  left: 0;
  width: 25px;
  padding: 5px;
  height: 25px;
}

form .form-group.radio-wrap .form-check input:checked + .disc span {
  background-color: #77b957;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

form .form-group.half .btn {
  width: 49%;
}

form .form-group.half .btn:last-of-type {
  float: right;
  margin-right: 0;
}

input[type=text].white-input, input[type=password].white-input, input[type=email].white-input, input[type=url].white-input, input[type=time].white-input, input[type=date].white-input, input[type=datetime-local].white-input, input[type=tel].white-input, input[type=number].white-input, input[type=search-md].white-input, input[type=search].white-input, textarea.textarea.white-input {
  width: calc(100% - 20px);
  color: #FFFFFF;
  border: 2px solid #FFFFFF !important;
  box-shadow: none !important;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 300;
  background: transparent;
  height: auto;
}

input[type=text].white-input::-webkit-input-placeholder, input[type=password].white-input::-webkit-input-placeholder, input[type=email].white-input::-webkit-input-placeholder, input[type=url].white-input::-webkit-input-placeholder, input[type=time].white-input::-webkit-input-placeholder, input[type=date].white-input::-webkit-input-placeholder, input[type=datetime-local].white-input::-webkit-input-placeholder, input[type=tel].white-input::-webkit-input-placeholder, input[type=number].white-input::-webkit-input-placeholder, input[type=search-md].white-input::-webkit-input-placeholder, input[type=search].white-input::-webkit-input-placeholder, textarea.textarea.white-input::-webkit-input-placeholder {
  color: #FFFFFF;
}

input[type=text].white-input:-moz-placeholder, input[type=password].white-input:-moz-placeholder, input[type=email].white-input:-moz-placeholder, input[type=url].white-input:-moz-placeholder, input[type=time].white-input:-moz-placeholder, input[type=date].white-input:-moz-placeholder, input[type=datetime-local].white-input:-moz-placeholder, input[type=tel].white-input:-moz-placeholder, input[type=number].white-input:-moz-placeholder, input[type=search-md].white-input:-moz-placeholder, input[type=search].white-input:-moz-placeholder, textarea.textarea.white-input:-moz-placeholder {
  color: #FFFFFF;
}

input[type=text].white-input::-moz-placeholder, input[type=password].white-input::-moz-placeholder, input[type=email].white-input::-moz-placeholder, input[type=url].white-input::-moz-placeholder, input[type=time].white-input::-moz-placeholder, input[type=date].white-input::-moz-placeholder, input[type=datetime-local].white-input::-moz-placeholder, input[type=tel].white-input::-moz-placeholder, input[type=number].white-input::-moz-placeholder, input[type=search-md].white-input::-moz-placeholder, input[type=search].white-input::-moz-placeholder, textarea.textarea.white-input::-moz-placeholder {
  color: #FFFFFF;
}

input[type=text].white-input:-ms-input-placeholder, input[type=password].white-input:-ms-input-placeholder, input[type=email].white-input:-ms-input-placeholder, input[type=url].white-input:-ms-input-placeholder, input[type=time].white-input:-ms-input-placeholder, input[type=date].white-input:-ms-input-placeholder, input[type=datetime-local].white-input:-ms-input-placeholder, input[type=tel].white-input:-ms-input-placeholder, input[type=number].white-input:-ms-input-placeholder, input[type=search-md].white-input:-ms-input-placeholder, input[type=search].white-input:-ms-input-placeholder, textarea.textarea.white-input:-ms-input-placeholder {
  color: #FFFFFF;
}

input[type=text].white-input:focus, input[type=password].white-input:focus, input[type=email].white-input:focus, input[type=url].white-input:focus, input[type=time].white-input:focus, input[type=date].white-input:focus, input[type=datetime-local].white-input:focus, input[type=tel].white-input:focus, input[type=number].white-input:focus, input[type=search-md].white-input:focus, input[type=search].white-input:focus, textarea.textarea.white-input:focus {
  border: 2px solid #FFFFFF;
  box-shadow: none !important;
  background: #FFFFFF;
  color: #6D6E71;
}

input[type=text].white-input:focus::-webkit-input-placeholder, input[type=password].white-input:focus::-webkit-input-placeholder, input[type=email].white-input:focus::-webkit-input-placeholder, input[type=url].white-input:focus::-webkit-input-placeholder, input[type=time].white-input:focus::-webkit-input-placeholder, input[type=date].white-input:focus::-webkit-input-placeholder, input[type=datetime-local].white-input:focus::-webkit-input-placeholder, input[type=tel].white-input:focus::-webkit-input-placeholder, input[type=number].white-input:focus::-webkit-input-placeholder, input[type=search-md].white-input:focus::-webkit-input-placeholder, input[type=search].white-input:focus::-webkit-input-placeholder, textarea.textarea.white-input:focus::-webkit-input-placeholder {
  color: #6D6E71;
}

input[type=text].white-input:focus:-moz-placeholder, input[type=password].white-input:focus:-moz-placeholder, input[type=email].white-input:focus:-moz-placeholder, input[type=url].white-input:focus:-moz-placeholder, input[type=time].white-input:focus:-moz-placeholder, input[type=date].white-input:focus:-moz-placeholder, input[type=datetime-local].white-input:focus:-moz-placeholder, input[type=tel].white-input:focus:-moz-placeholder, input[type=number].white-input:focus:-moz-placeholder, input[type=search-md].white-input:focus:-moz-placeholder, input[type=search].white-input:focus:-moz-placeholder, textarea.textarea.white-input:focus:-moz-placeholder {
  color: #6D6E71;
}

input[type=text].white-input:focus::-moz-placeholder, input[type=password].white-input:focus::-moz-placeholder, input[type=email].white-input:focus::-moz-placeholder, input[type=url].white-input:focus::-moz-placeholder, input[type=time].white-input:focus::-moz-placeholder, input[type=date].white-input:focus::-moz-placeholder, input[type=datetime-local].white-input:focus::-moz-placeholder, input[type=tel].white-input:focus::-moz-placeholder, input[type=number].white-input:focus::-moz-placeholder, input[type=search-md].white-input:focus::-moz-placeholder, input[type=search].white-input:focus::-moz-placeholder, textarea.textarea.white-input:focus::-moz-placeholder {
  color: #6D6E71;
}

input[type=text].white-input:focus:-ms-input-placeholder, input[type=password].white-input:focus:-ms-input-placeholder, input[type=email].white-input:focus:-ms-input-placeholder, input[type=url].white-input:focus:-ms-input-placeholder, input[type=time].white-input:focus:-ms-input-placeholder, input[type=date].white-input:focus:-ms-input-placeholder, input[type=datetime-local].white-input:focus:-ms-input-placeholder, input[type=tel].white-input:focus:-ms-input-placeholder, input[type=number].white-input:focus:-ms-input-placeholder, input[type=search-md].white-input:focus:-ms-input-placeholder, input[type=search].white-input:focus:-ms-input-placeholder, textarea.textarea.white-input:focus:-ms-input-placeholder {
  color: #6D6E71;
}

.custom-check {
  position: absolute;
  opacity: 0;
}

.custom-check + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-weight: normal;
}

.custom-check + label span {
  display: inline-block;
  float: left;
  width: calc(100% - 25px);
}

.custom-check + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 3px;
  transform: translateY(5px);
  float: left;
}

.custom-check:hover + label:before {
  background: rgba(255, 255, 255, 0.3);
}

.custom-check:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.custom-check:checked + label:before {
  background: #FFFFFF;
}

.custom-check:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.custom-check:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.custom-check:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: transparent;
  width: 2px;
  height: 2px;
  transform: rotate(45deg);
}

input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime-local], input[type=tel], input[type=number], input[type=search-md], input[type=search], textarea.textarea {
  background: #FFFFFF;
  border-radius: 5px;
  border-bottom: 0;
  box-shadow: none !important;
  padding: 5px 15px;
  width: calc(100% - 30px);
  outline: none !important;
  color: #76787a !important;
}

input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=url]:focus, input[type=time]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=tel]:focus, input[type=number]:focus, input[type=search-md]:focus, input[type=search]:focus, textarea.textarea:focus {
  box-shadow: none !important;
  border-bottom: 0;
}

textarea.textarea {
  height: 100px;
  resize: none;
  padding: 10px 15px;
  width: 100%;
  border: 0;
}

.background-white {
  background-color: #FFFFFF !important;
}

.text-white {
  color: #FFFFFF !important;
}

.background-black {
  background-color: #000000 !important;
}

.text-black {
  color: #000000 !important;
}

.background-dark-green {
  background-color: #188d5f !important;
}

.text-dark-green {
  color: #188d5f !important;
}

.background-green {
  background-color: #77b957 !important;
}

.text-green {
  color: #77b957 !important;
}

.background-orange {
  background-color: #de6d3d !important;
}

.text-orange {
  color: #de6d3d !important;
}

.background-yellow {
  background-color: #f8c453 !important;
}

.text-yellow {
  color: #f8c453 !important;
}

.background-pink {
  background-color: #d44e8f !important;
}

.text-pink {
  color: #d44e8f !important;
}

.background-dark-blue {
  background-color: #1f778e !important;
}

.text-dark-blue {
  color: #1f778e !important;
}

.background-light-blue {
  background-color: #48c0de !important;
}

.text-light-blue {
  color: #48c0de !important;
}

.background-purple {
  background-color: #5a528f !important;
}

.text-purple {
  color: #5a528f !important;
}

.background-grey {
  background-color: #76787a !important;
}

.text-grey {
  color: #76787a !important;
}

.background-light-grey {
  background-color: #F1F1F2 !important;
}

.text-light-grey {
  color: #F1F1F2 !important;
}

.background-off-white {
  background-color: #e7e7e7 !important;
}

.text-off-white {
  color: #e7e7e7 !important;
}

.background-cream {
  background-color: #d4c3a3 !important;
}

.text-cream {
  color: #d4c3a3 !important;
}

.background-before-people {
  background-color: #188d5f !important;
}

.text-before-people {
  color: #188d5f !important;
}

.background-first-settlers {
  background-color: #de6d3d !important;
}

.text-first-settlers {
  color: #de6d3d !important;
}

.background-romans {
  background-color: #48c0de !important;
}

.text-romans {
  color: #48c0de !important;
}

.background-saxons-vikings-normans {
  background-color: #f8c453 !important;
}

.text-saxons-vikings-normans {
  color: #f8c453 !important;
}

.background-medieval {
  background-color: #d44e8f !important;
}

.text-medieval {
  color: #d44e8f !important;
}

.background-tudors-stuarts {
  background-color: #1f778e !important;
}

.text-tudors-stuarts {
  color: #1f778e !important;
}

.background-markets-to-railways {
  background-color: #5a528f !important;
}

.text-markets-to-railways {
  color: #5a528f !important;
}

.background-brick-town-to-new-town {
  background-color: #77b957 !important;
}

.text-brick-town-to-new-town {
  color: #77b957 !important;
}

.divider {
  text-align: center;
  color: #FFFFFF;
  display: block;
  position: relative;
}

.divider:before, .divider:after {
  content: "";
  position: absolute;
  top: 22%;
  left: 0;
  width: 45%;
  height: 1px;
  background: #FFFFFF;
  display: block;
}

.divider:after {
  left: auto;
  right: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*! jQuery.Flipster, v1.0.1 (built 2015-11-02) */
/* @group Flipster Essential Styles */
.flipster {
  margin: 0 auto;
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  width: calc(100%);
}

.flipster:focus {
  outline: none;
}

.flipster__container,
.flipster__item {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.flipster__container {
  display: block;
  white-space: nowrap;
  word-spacing: -0.25em;
  transform-origin: 50% 50%;
}

.flipster__item {
  display: inline-block;
  white-space: normal;
  word-spacing: normal;
  vertical-align: bottom;
}

.flipster__item img {
  max-width: 100%;
}

/* @end */
/* @group Flipster Previous & Next Buttons */
.flipster__button {
  position: absolute;
  top: 50%;
  display: block;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  z-index: 999;
  cursor: pointer;
  font-size: 15px;
  opacity: 1;
  transition: opacity 500ms ease;
  margin: -1em 2em;
}

.flipster__button svg {
  width: 2em;
  stroke: #77b957;
  fill: transparent;
  stroke-width: 3;
  stroke-linecap: round;
}

.flipster__button:hover,
.flipster__button:focus {
  opacity: 1;
  background: transparent;
}

.flipster__button:hover svg,
.flipster__button:focus svg {
  stroke: #77b957;
}

.flipster__button--prev {
  left: -30px;
}

.flipster__button--next {
  right: -30px;
}

/* @end */
/* @group Flipster Navigation */
.flipster__nav,
.flipster__nav__item {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.flipster__nav {
  display: block;
  margin: 0 0 4em;
  text-align: center;
  position: relative;
}

.flipster__nav__item {
  display: inline-block;
  margin: 0 0.25em;
}

.flipster__nav__link {
  display: block;
  color: inherit;
  padding: 0.5em 1em;
  position: relative;
  overflow: hidden;
  transition: all 250ms ease-out;
  transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
}

.flipster__nav__link::after {
  content: '';
  display: block;
  background: #232221;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: translateY(100%) translateY(-0.25em);
  transition: inherit;
}

.flipster__nav__item--current > .flipster__nav__link,
.flipster__nav__link:hover,
.flipster__nav__link:focus {
  color: #FFF;
}

.flipster__nav__item--current > .flipster__nav__link::after,
.flipster__nav__link:hover::after,
.flipster__nav__link:focus::after {
  transform: translateY(0);
}

.flipster__nav__child {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -1px;
  padding: 0.5em;
  background: #4e4441;
  z-index: 1;
}

.flipster__nav__child .flipster__nav__link {
  color: #FFF;
}

.flipster__nav__child .flipster__nav__link::after {
  background: #FFF;
}

.flipster__nav__child .flipster__nav__item--current > .flipster__nav__link,
.flipster__nav__child .flipster__nav__link:hover,
.flipster__nav__child .flipster__nav__link:focus {
  color: #232221;
}

.flipster__nav__item--current .flipster__nav__child {
  display: block;
}

/* @end */
/* @group Flipster Carousel Theme */
.flipster--carousel .flipster__container,
.flipster--carousel .flipster__item,
.flipster--carousel .flipster__item__content {
  transition: all 350ms ease-in-out;
  transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
}

.flipster--carousel .flipster__item--past,
.flipster--carousel .flipster__item--future {
  opacity: 0;
  transition-delay: 115ms;
}

.flipster--carousel .flipster__item--past-2,
.flipster--carousel .flipster__item--future-2 {
  opacity: 1;
  transition-delay: 90ms;
}

.flipster--carousel .flipster__item--past-1,
.flipster--carousel .flipster__item--future-1 {
  opacity: 1;
  transition-delay: 60ms;
}

.flipster--carousel .flipster__item--past .flipster__item__content {
  transform: translateX(100%) rotateY(0deg) scale(0.5);
}

.flipster--carousel .flipster__item--past-2 .flipster__item__content {
  transform: translateX(25%) rotateY(0deg) scale(0.65);
}

.flipster--carousel .flipster__item--past-1 .flipster__item__content {
  transform: rotateY(0deg) scale(0.8);
}

.flipster--carousel .flipster__item--future .flipster__item__content {
  transform: translateX(-100%) rotateY(0deg) scale(0.5);
}

.flipster--carousel .flipster__item--future-2 .flipster__item__content {
  transform: translateX(-25%) rotateY(0deg) scale(0.65);
}

.flipster--carousel .flipster__item--future-1 .flipster__item__content {
  transform: rotateY(0deg) scale(0.8);
}

.flipster--carousel.no-rotate .flipster__item--past .flipster__item__content {
  transform: translateX(175%) scale(0.5);
}

.flipster--carousel.no-rotate .flipster__item--past-2 .flipster__item__content {
  transform: translateX(25%) scale(0.65);
}

.flipster--carousel.no-rotate .flipster__item--past-1 .flipster__item__content {
  transform: translateX(0%) scale(0.8);
}

.flipster--carousel.no-rotate .flipster__item--future .flipster__item__content {
  transform: translateX(-175%) scale(0.5);
}

.flipster--carousel.no-rotate .flipster__item--future-2 .flipster__item__content {
  transform: translateX(-25%) scale(0.65);
}

.flipster--carousel.no-rotate .flipster__item--future-1 .flipster__item__content {
  transform: translateX(0%) scale(0.8);
}

.flipster--carousel .flipster__item--current .flipster__item__content {
  transform: translateX(0) rotateY(0deg) scale(1);
  transition-delay: 60ms;
}

/* @end */
/* @group Flipster Coverflow Theme */
.flipster--coverflow .flipster__container,
.flipster--coverflow .flipster__item,
.flipster--coverflow .flipster__item__content {
  transition: all 350ms ease-in-out;
  transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
}

.flipster--coverflow .flipster__container {
  padding-bottom: 5%;
}

.flipster--coverflow .flipster__item__content {
  transform-origin: 50% 100%;
  box-reflect: below 0 -webkit-gradient(linear, left bottom, left top, color-stop(0.05, rgba(255, 255, 255, 0.12)), color-stop(0.2, transparent));
  -webkit-box-reflect: below 0 -webkit-gradient(linear, left bottom, left top, color-stop(0.05, rgba(255, 255, 255, 0.12)), color-stop(0.2, transparent));
}

.flipster--coverflow .flipster__item__content img:only-child {
  display: block;
}

.flipster--coverflow .flipster__item--past .flipster__item__content {
  transform-origin: 0% 50%;
  transform: scale(0.75) rotateY(0deg);
}

.flipster--coverflow .flipster__item--future .flipster__item__content {
  transform-origin: 100% 50%;
  transform: scale(0.75) rotateY(0deg);
}

.flipster--coverflow .flip-current .flipster__item__content {
  transform: rotateY(0deg);
}

/* @end */
/* @group Flat */
.flipster--flat .flipster__container,
.flipster--flat .flipster__item,
.flipster--flat .flipster__item__content {
  transition: all 400ms ease-in-out;
}

.flipster--flat .flipster__item--past,
.flipster--flat .flipster__item--future {
  opacity: 1;
}

.flipster--flat .flipster__item--past .flipster__item__content {
  transform: scale(0.75);
}

.flipster--flat .flipster__item--future .flipster__item__content {
  transform: scale(0.75);
}

/* @end */
/* @group Flipster Wheel Theme */
.flipster--wheel {
  overflow: hidden;
}

.flipster--wheel .flipster__container,
.flipster--wheel .flipster__item__content {
  transition: all 400ms ease-in-out;
  transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
}

.flipster--wheel .flipster__container {
  padding-bottom: 20%;
}

.flipster--wheel .flipster__item__content {
  transform-origin: 50% 100%;
}

.flipster--wheel .flipster__item--past .flipster__item__content {
  transform-origin: 100% 100%;
}

.flipster--wheel .flipster__item--future .flipster__item__content {
  transform-origin: 0% 100%;
}

.flipster--wheel .flipster__item__content img:only-child {
  display: block;
}

.flipster--wheel .flipster__item--past .flipster__item__content {
  opacity: 0;
  transform: rotateZ(-80deg) translate(-170%, 110%);
}

.flipster--wheel .flipster__item--future .flipster__item__content {
  opacity: 0;
  transform: rotateZ(80deg) translate(170%, 110%);
}

.flipster--wheel .flipster__item--past-3 .flipster__item__content {
  opacity: 1;
  transform: rotateZ(-60deg) translate(-70%, 75%);
}

.flipster--wheel .flipster__item--future-3 .flipster__item__content {
  opacity: 1;
  transform: rotateZ(60deg) translate(70%, 75%);
}

.flipster--wheel .flipster__item--past-2 .flipster__item__content {
  opacity: 1;
  transform: rotateZ(-40deg) translate(-17%, 30%);
}

.flipster--wheel .flipster__item--future-2 .flipster__item__content {
  opacity: 1;
  transform: rotateZ(40deg) translate(17%, 30%);
}

.flipster--wheel .flipster__item--past-1 .flipster__item__content {
  opacity: 1;
  transform: rotateZ(-20deg);
}

.flipster--wheel .flipster__item--future-1 .flipster__item__content {
  opacity: 1;
  transform: rotateZ(20deg);
}

.flipster--wheel .flip-current .flipster__item__content {
  transform: rotateX(0deg);
}

.flipster__item--current .card {
  box-shadow: 0px 0px 0px black;
}

.flipster__container .card {
  box-shadow: 0px 0px 0px 20px rgba(0, 0, 0, 0.075);
}

.flipster.colour-white .flipster__button svg {
  stroke: #FFFFFF;
}

.flipster.colour-black .flipster__button svg {
  stroke: #000000;
}

.flipster.colour-dark-green .flipster__button svg {
  stroke: #188d5f;
}

.flipster.colour-green .flipster__button svg {
  stroke: #77b957;
}

.flipster.colour-orange .flipster__button svg {
  stroke: #de6d3d;
}

.flipster.colour-yellow .flipster__button svg {
  stroke: #f8c453;
}

.flipster.colour-pink .flipster__button svg {
  stroke: #d44e8f;
}

.flipster.colour-dark-blue .flipster__button svg {
  stroke: #1f778e;
}

.flipster.colour-light-blue .flipster__button svg {
  stroke: #48c0de;
}

.flipster.colour-purple .flipster__button svg {
  stroke: #5a528f;
}

.flipster.colour-grey .flipster__button svg {
  stroke: #76787a;
}

.flipster.colour-light-grey .flipster__button svg {
  stroke: #F1F1F2;
}

.flipster.colour-off-white .flipster__button svg {
  stroke: #e7e7e7;
}

.flipster.colour-cream .flipster__button svg {
  stroke: #d4c3a3;
}

.flipster.colour-before-people .flipster__button svg {
  stroke: #188d5f;
}

.flipster.colour-first-settlers .flipster__button svg {
  stroke: #de6d3d;
}

.flipster.colour-romans .flipster__button svg {
  stroke: #48c0de;
}

.flipster.colour-saxons-vikings-normans .flipster__button svg {
  stroke: #f8c453;
}

.flipster.colour-medieval .flipster__button svg {
  stroke: #d44e8f;
}

.flipster.colour-tudors-stuarts .flipster__button svg {
  stroke: #1f778e;
}

.flipster.colour-markets-to-railways .flipster__button svg {
  stroke: #5a528f;
}

.flipster.colour-brick-town-to-new-town .flipster__button svg {
  stroke: #77b957;
}

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
}

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
}

.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools {
  right: auto;
  left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0;
  left: -26px;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
}

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

.mCSB_scrollTools {
  -webkit-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
}

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
}

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)";
}

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 10px;
  background-color: #b7b8b9;
  opacity: 0.3;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
  background-color: #86c169;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #b1d79f;
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)";
}

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #b1d79f;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  background-repeat: no-repeat;
  opacity: .4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_buttonUp {
  background-image: url(mCSB_buttons.png);
  background-repeat: no-repeat;
  opacity: .4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
  background-position: 0 0;
}

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
}

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
}

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
}

.mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover, .mCSB_scrollTools .mCSB_buttonUp:hover {
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active, .mCSB_scrollTools .mCSB_buttonUp:active {
  opacity: .9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0;
}

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: .3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=);
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}

.mCS-3d-dark.mCSB_scrollTools, .mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px;
}

.mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal, .mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.color-white .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #FFFFFF;
}

.color-white .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: white;
}

.color-black .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000000;
}

.color-black .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #1a1919;
}

.color-dark-green .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #188d5f;
}

.color-dark-green .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #1fb97c;
}

.color-green .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #77b957;
}

.color-green .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #94c87b;
}

.color-orange .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #de6d3d;
}

.color-orange .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #e58e69;
}

.color-yellow .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #f8c453;
}

.color-yellow .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fad584;
}

.color-pink .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #d44e8f;
}

.color-pink .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #de77a9;
}

.color-dark-blue .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #1f778e;
}

.color-dark-blue .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #289ab8;
}

.color-light-blue .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #48c0de;
}

.color-light-blue .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #73cfe6;
}

.color-purple .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #5a528f;
}

.color-purple .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #736aaa;
}

.color-grey .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #76787a;
}

.color-grey .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #909293;
}

.color-light-grey .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #F1F1F2;
}

.color-light-grey .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: white;
}

.color-off-white .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #e7e7e7;
}

.color-off-white .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: white;
}

.color-cream .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #d4c3a3;
}

.color-cream .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #e4dac6;
}

.color-before-people .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #188d5f;
}

.color-before-people .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #1fb97c;
}

.color-first-settlers .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #de6d3d;
}

.color-first-settlers .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #e58e69;
}

.color-romans .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #48c0de;
}

.color-romans .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #73cfe6;
}

.color-saxons-vikings-normans .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #f8c453;
}

.color-saxons-vikings-normans .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fad584;
}

.color-medieval .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #d44e8f;
}

.color-medieval .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #de77a9;
}

.color-tudors-stuarts .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #1f778e;
}

.color-tudors-stuarts .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #289ab8;
}

.color-markets-to-railways .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #5a528f;
}

.color-markets-to-railways .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #736aaa;
}

.color-brick-town-to-new-town .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #77b957;
}

.color-brick-town-to-new-town .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #94c87b;
}

.bx-controls .bx-controls-direction .bx-next, .bx-controls .bx-controls-direction .bx-prev {
  display: block;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  outline: 0;
  width: 32px;
  height: 32px;
  z-index: 9999;
  font-size: 30pt;
}

.bx-wrapper .bx-next {
  right: -36px;
}

.bx-wrapper .bx-prev {
  left: -36px;
}

/* This is the core CSS of Tooltipster */
/* GENERAL STRUCTURE RULES (do not edit this section) */
.tooltipster-base {
  /* this ensures that a constrained height set by functionPosition,
    if greater that the natural height of the tooltip, will be enforced
    in browsers that support display:flex */
  display: flex;
  pointer-events: none;
  /* this may be overriden in JS for fixed position origins */
  position: absolute;
  font-family: "Rubik", sans-serif !important;
}

.tooltipster-base * {
  font-family: "Rubik", sans-serif !important;
}

.tooltipster-box {
  /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
    and flex-basis auto for IE11- (at least) */
  flex: 1 1 auto;
}

.tooltipster-content {
  /* prevents an overflow if the user adds padding to the div */
  box-sizing: border-box;
  /* these make sure we'll be able to detect any overflow */
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  font-family: "Rubik", sans-serif !important;
}

.tooltipster-ruler {
  /* these let us test the size of the tooltip without overflowing the window */
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

/* ANIMATIONS */
/* Open/close animations */
/* fade */
.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}

.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

/* grow */
.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}

.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

/* swing */
.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}

.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}

/* fall */
.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}

.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}

/* slide */
.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}

.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}

/* Update animations */
/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */
/* fade */
@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading 400ms;
}

/* rotate */
@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

.tooltipster-update-rotate {
  animation: tooltipster-rotating 600ms;
}

/* scale */
@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tooltipster-update-scale {
  animation: tooltipster-scaling 600ms;
}

/**
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 *
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins.
 */
/* .tooltipster-box */
.tooltipster-sidetip .tooltipster-box {
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  padding: 3px;
  color: #76787a !important;
  font-family: "Rubik", sans-serif;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 8px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 8px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 8px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 8px;
}

/* .tooltipster-content */
.tooltipster-sidetip .tooltipster-content {
  color: #76787a;
  line-height: 18px;
  padding: 6px 14px;
  font-family: "Rubik", sans-serif !important;
  font-weight: 600;
  font-size: 20px;
}

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */
.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 10px;
  /* half the width, for centering */
  margin-left: -10px;
  top: 0;
  width: 20px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  right: 0;
  /* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
    been positioned yet */
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  left: 0;
  /* same as .tooltipster-left .tooltipster-arrow */
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: 10px;
  margin-left: -10px;
  width: 20px;
}

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */
.tooltipster-sidetip .tooltipster-arrow-background, .tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0;
}

/* .tooltipster-arrow-background */
.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #FFFFFF;
  left: 0;
  top: 3px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #FFFFFF;
  left: -3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #FFFFFF;
  left: 3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #FFFFFF;
  left: 0;
  top: -3px;
}

/* .tooltipster-arrow-border */
.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #FFFFFF;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #FFFFFF;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #FFFFFF;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #FFFFFF;
}

/* tooltipster-arrow-uncropped */
.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #77b957;
  position: fixed;
  z-index: 9999999999999999999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 10px;
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 20px #77b957, 0 0 10px #77b957;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 32px;
  height: 32px;
  border: solid 5px transparent;
  border-top-color: #77b957;
  border-left-color: #77b957;
  border-radius: 32px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

li.categories-link {
  width: 145px;
  cursor: pointer;
  align-self: flex-start;
  display: inline-block;
  transform: scale(1);
  box-sizing: border-box;
  position: relative;
}

li.categories-link a {
  color: #FFFFFF;
}

li.categories-link span.title {
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 11pt;
  font-weight: 300;
  position: absolute;
  line-height: 1.5;
}

@media only screen and (max-width: 992px) {
  li.categories-link span.title {
    font-size: 8pt;
    line-height: 1.3;
  }
}

li.categories-link .icon-container {
  display: block;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  margin-top: 12px;
  background: #77b957;
  border-radius: 150px;
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.15);
  transition: all ease 0.25s;
  position: relative;
  text-align: center;
  will-change: transform;
  transform: scale(1);
}

@media only screen and (max-width: 992px) {
  li.categories-link .icon-container {
    width: 50px;
    height: 50px;
  }
}

li.categories-link .icon-container:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  transform-origin: center center;
  transform: translateX(-50%) translateY(-50%);
  border: 2px solid transparent;
  transition: all ease .25s;
  opacity: 0;
}

li.categories-link .icon-container svg, li.categories-link .icon-container .fa, li.categories-link .icon-container img {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

li.categories-link:hover .icon-container, li.categories-link.active .icon-container {
  transform: scale(1.1);
}

li.categories-link:hover .icon-container:after, li.categories-link.active .icon-container:after {
  border: 2px solid #77b957;
  opacity: 0;
  width: 88px;
  height: 88px;
  opacity: 1;
  border-radius: 500px;
}

li.categories-link:hover .icon-container.background-white:after, li.categories-link.active .icon-container.background-white:after {
  border: 2px solid #FFFFFF;
}

li.categories-link:hover .icon-container.background-black:after, li.categories-link.active .icon-container.background-black:after {
  border: 2px solid #000000;
}

li.categories-link:hover .icon-container.background-dark-green:after, li.categories-link.active .icon-container.background-dark-green:after {
  border: 2px solid #188d5f;
}

li.categories-link:hover .icon-container.background-green:after, li.categories-link.active .icon-container.background-green:after {
  border: 2px solid #77b957;
}

li.categories-link:hover .icon-container.background-orange:after, li.categories-link.active .icon-container.background-orange:after {
  border: 2px solid #de6d3d;
}

li.categories-link:hover .icon-container.background-yellow:after, li.categories-link.active .icon-container.background-yellow:after {
  border: 2px solid #f8c453;
}

li.categories-link:hover .icon-container.background-pink:after, li.categories-link.active .icon-container.background-pink:after {
  border: 2px solid #d44e8f;
}

li.categories-link:hover .icon-container.background-dark-blue:after, li.categories-link.active .icon-container.background-dark-blue:after {
  border: 2px solid #1f778e;
}

li.categories-link:hover .icon-container.background-light-blue:after, li.categories-link.active .icon-container.background-light-blue:after {
  border: 2px solid #48c0de;
}

li.categories-link:hover .icon-container.background-purple:after, li.categories-link.active .icon-container.background-purple:after {
  border: 2px solid #5a528f;
}

li.categories-link:hover .icon-container.background-grey:after, li.categories-link.active .icon-container.background-grey:after {
  border: 2px solid #76787a;
}

li.categories-link:hover .icon-container.background-light-grey:after, li.categories-link.active .icon-container.background-light-grey:after {
  border: 2px solid #F1F1F2;
}

li.categories-link:hover .icon-container.background-off-white:after, li.categories-link.active .icon-container.background-off-white:after {
  border: 2px solid #e7e7e7;
}

li.categories-link:hover .icon-container.background-cream:after, li.categories-link.active .icon-container.background-cream:after {
  border: 2px solid #d4c3a3;
}

li.categories-link:hover .icon-container.background-before-people:after, li.categories-link.active .icon-container.background-before-people:after {
  border: 2px solid #188d5f;
}

li.categories-link:hover .icon-container.background-first-settlers:after, li.categories-link.active .icon-container.background-first-settlers:after {
  border: 2px solid #de6d3d;
}

li.categories-link:hover .icon-container.background-romans:after, li.categories-link.active .icon-container.background-romans:after {
  border: 2px solid #48c0de;
}

li.categories-link:hover .icon-container.background-saxons-vikings-normans:after, li.categories-link.active .icon-container.background-saxons-vikings-normans:after {
  border: 2px solid #f8c453;
}

li.categories-link:hover .icon-container.background-medieval:after, li.categories-link.active .icon-container.background-medieval:after {
  border: 2px solid #d44e8f;
}

li.categories-link:hover .icon-container.background-tudors-stuarts:after, li.categories-link.active .icon-container.background-tudors-stuarts:after {
  border: 2px solid #1f778e;
}

li.categories-link:hover .icon-container.background-markets-to-railways:after, li.categories-link.active .icon-container.background-markets-to-railways:after {
  border: 2px solid #5a528f;
}

li.categories-link:hover .icon-container.background-brick-town-to-new-town:after, li.categories-link.active .icon-container.background-brick-town-to-new-town:after {
  border: 2px solid #77b957;
}

.categories-navigation {
  display: none;
}

.categories-navigation ul {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
}

.categories-navigation ul:before {
  content: "";
  position: absolute;
  top: 45px;
  width: calc(100% - 200px);
  left: 100px;
  z-index: 0;
  height: 10px;
  background: #fff;
}

@media only screen and (max-width: 1024px) {
  .categories-navigation ul:before {
    width: calc(100% - 100px);
    left: 50px;
  }
}

@media only screen and (max-width: 992px) {
  .categories-navigation {
    display: none !important;
  }
}

.navigation-links {
  background: white;
  margin-left: auto;
  margin-right: 40px;
  height: 50px;
  border-radius: 0 0 20px 20px;
  padding: 10px;
  min-width: 100px;
  color: #76787a;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 275px;
}

.navigation-links a {
  color: #76787a;
  font-weight: 400;
}

.navigation-links a:first-of-type {
  border-right: 2px solid #76787a;
  margin-right: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 992px) {
  .navigation-links {
    display: inline-block;
    float: right;
  }
}

nav.navigation {
  height: 100px;
  margin-bottom: 20px;
}

.navigation-secondary {
  display: block;
  width: 100%;
  padding-right: 50px;
  text-align: right;
  padding-top: 20px;
}

.navigation-secondary a.search-link {
  font-size: 2rem;
  color: #FFFFFF;
}

@media only screen and (max-width: 992px) {
  .navigation-secondary a.search-link {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .navigation-secondary {
    display: inline-block;
    float: right;
    padding-right: 20px;
    padding-top: 10px;
    width: auto;
  }
}

body {
  background-color: #000000;
  transition: none;
  position: relative;
}

body .main-body {
  position: relative;
  width: 100%;
  color: #FFFFFF;
  display: block;
  margin: 0 auto;
  width: calc(100% - 500px);
  margin-left: 250px;
  transition: all ease 0.25s;
  height: 100vh;
}

body .main-body.sidebar-open {
  margin-left: 350px;
}

@media only screen and (max-width: 1300px) {
  body .main-body {
    width: calc(100% - 200px);
    margin-left: 100px;
  }
}

@media only screen and (max-width: 992px) {
  body .main-body {
    width: 100%;
    margin-left: 0;
    padding-bottom: 100px;
    padding-left: 50px;
    padding-right: 10px;
  }
}

.main-content {
  margin-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 992px) {
  .main-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 100px;
    margin-top: 40px;
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;
  padding: 50px;
  transform: translateY(-100%);
  opacity: 0;
  transition: all ease 0.25s;
  overflow: hidden;
}

.overlay.open {
  transform: translateY(0);
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .overlay.open {
    overflow: hidden;
  }
}

@media only screen and (max-width: 992px) {
  .overlay {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
}

.close-overlay, .close-overlay-button {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 4px;
  font-size: 38px;
  color: #FFFFFF;
  z-index: 999999999999;
}

@media only screen and (max-width: 992px) {
  .close-overlay, .close-overlay-button {
    right: 20px;
  }
}

.reponsive-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000;
  z-index: 99999999999;
  display: none;
}

.reponsive-overlay .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

.reponsive-overlay .content h4 {
  margin-top: 30px;
  color: #FFFFFF;
}

@media only screen and (orientation: portrait) {
  .reponsive-overlay {
    display: block;
  }
}

.mobile-categories {
  display: none;
  position: fixed;
  top: 100%;
  left: 0;
  height: auto;
  padding-bottom: 50px;
  width: 100%;
  background: #f7f8f8;
  padding-top: 5px;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  transition: all ease 0.25s;
  transform: translateY(0);
  z-index: 999;
}

@media only screen and (max-width: 992px) {
  .mobile-categories {
    display: block !important;
  }
  .mobile-categories .categories-navigation {
    display: block !important;
  }
  .mobile-categories .categories-navigation ul:before {
    display: none;
  }
}

.mobile-categories.open {
  transform: translateY(-100%);
}

.mobile-categories li.categories-link a {
  color: #000000;
}

.mobile-categories .toggle-mobile-categories {
  position: absolute;
  bottom: 100%;
  padding-top: 2px;
  width: 200px;
  right: 50px;
  border-radius: 100% 100% 0 0;
  background: #f7f8f8;
  color: #000000;
  height: 50px;
  line-height: 45px;
  font-size: 50px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .overlay {
    width: 100%;
    height: 100%;
  }
  body {
    zoom: .8;
  }
  body .main-body {
    height: 100%;
  }
  body .story-overlay .story-inner .content {
    height: unset;
    overflow: unset;
  }
  body .pace:after {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 800px) {
  body {
    zoom: .9;
  }
}

@media only screen and (min-width: 1000px) {
  body {
    zoom: 1;
  }
}

.pagination .page-link {
  color: #FFFFFF;
  margin-left: 0;
}

.pagination .page-item.active {
  border-color: #77b957;
}

.pagination .page-item.disabled .page-link {
  color: #6D6E71;
}

.pagination .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination .page-item.active .page-link {
  background-color: #77b957;
  border-color: #77b957;
}

#select-a-story .pagination li a {
  text-align: center;
  padding-left: 0;
  padding-right: 0 !important;
}

form.search-form {
  padding-top: 20px;
}

.search-input-group {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.search-input-group:last-of-type {
  margin-right: 0;
}

.search-input-group.large {
  width: 74%;
}

.search-input-group.small {
  width: 10.5%;
}

@media only screen and (max-width: 992px) {
  .search-input-group {
    margin-right: 0;
  }
  .search-input-group.large {
    width: 100%;
    margin-bottom: 10px;
  }
  .search-input-group.small {
    width: 45%;
  }
  .search-input-group.small:last-of-type {
    margin-left: 20px;
  }
  .search-input-group.small.margin-right-0 {
    float: right;
  }
}

.search-matrices {
  margin-top: 20px;
}

.search-matrix-container {
  width: calc((100% / 5) - 20px);
  max-height: 230px;
  min-height: 230px;
  overflow: hidden;
  display: inline-block;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.search-matrix-container:nth-of-type(5n+5) {
  margin-right: 0;
}

.search-matrix-container h5 {
  margin-bottom: 10px;
  font-size: 18px;
}

.search-matrix-container label {
  margin-bottom: 0;
}

.search-matrix-container ul {
  max-height: 180px;
  min-height: 180px;
  overflow: scroll;
  list-style-type: none;
  padding-left: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .search-matrix-container {
    width: calc((100% / 2) - 20px);
  }
}

.search-matrix-container .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #76787a;
}

.search-matrix-container .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #909293;
}

.search-matrix-container .mCSB_scrollTools .mCSB_draggerRail, .search-matrix-container .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 0;
}

.search-matrix-container .mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
}

.search-matrix-container .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
}

li.search-matrix-term {
  list-style-type: none;
  display: block;
  width: 100%;
}

li.search-matrix-term:after {
  content: "";
  display: table;
  clear: both;
}

.sidebar-bottom-links {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.sidebar-bottom-links li {
  padding-right: 5px;
  margin-right: 5px;
  display: inline-block;
  border-right: 1px solid #76787a;
  font-size: 0.9rem;
}

.sidebar-bottom-links li:last-of-type {
  border-right: 0;
}

.sidebar-bottom-links li a {
  color: #76787a;
}

.sidebar-company-logos {
  margin-bottom: 0;
}

.sidebar-company-logos li {
  list-style-type: none;
  text-align: center;
  width: 49%;
  display: inline-block;
  vertical-align: center;
}

.sidebar-company-logos li img {
  max-height: 25px;
}

.sidebar-copyright-text {
  padding: 20px;
  background: rgba(248, 196, 83, 0.6);
  color: #FFFFFF;
  font-size: 0.75rem;
}

p.sidebar-intro-text {
  margin-top: 20px;
  line-height: 1.3;
  font-size: 1rem;
  font-weight: 400;
}

img.sidebar-logo {
  display: block;
  max-width: 75%;
  margin: 0 auto;
}

aside.sidebar {
  position: fixed;
  left: 0;
  width: 300px;
  background-color: #e9e5d6;
  height: 100%;
  background-image: url("../images/sidebar/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Rubik", sans-serif;
  max-height: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transform: translateX(-100%);
  transition: all ease 0.25s;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  will-change: transform;
  z-index: 9999;
}

aside.sidebar .inner {
  overflow: scroll;
  height: 100%;
}

@media only screen and (max-width: 992px) {
  aside.sidebar {
    display: block;
  }
  aside.sidebar .inner {
    overflow: scroll;
  }
}

aside.sidebar .secondary-logo {
  content: "";
  position: absolute;
  top: 25px;
  left: calc(100% + 25px);
  width: 150px;
  height: 128px;
  background-image: url("../images/sidebar/logo-reversed.svg");
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.25s;
  z-index: 2;
}

@media only screen and (max-width: 1300px) {
  aside.sidebar .secondary-logo {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 992px) {
  aside.sidebar .secondary-logo {
    display: none;
  }
}

aside.sidebar.open {
  transform: translateX(0);
  box-shadow: 0px 0px 10px #000000;
}

aside.sidebar.open .secondary-logo {
  opacity: 0;
  left: -150px;
  z-index: -1;
}

aside.sidebar .main {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 25px;
}

aside.sidebar .bottom-drawer {
  width: 100%;
}

aside.sidebar .bottom-drawer ul {
  margin-left: 0;
  padding-left: 0;
}

@media only screen and (max-width: 992px) {
  aside.sidebar .bottom-drawer {
    position: static;
  }
}

.sidebar-social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.sidebar-social-links li {
  list-style-type: none;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.sidebar-social-links li a {
  font-size: 25px;
  color: #6d6e71;
}

.sidebar-todays-card .paper-stick {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/sidebar/taped-paper.png");
  padding-top: 41px;
  padding-bottom: 20px;
  margin-left: -20px;
  padding-left: 90px;
  filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.4));
  display: block;
  margin-bottom: 25px;
}

.sidebar-todays-card .paper-stick h5 {
  color: #6D6E71;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 26px;
  width: calc(100% - 5px);
}

.sidebar-todays-card .paper-stick .btn {
  color: #FFFFFF !important;
  padding-left: 20px;
  padding-right: 20px;
}

.sidebar-toggle-button {
  background-image: url("../images/sidebar/toggle-button.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 65px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 265px;
  color: #FFFFFF;
  transform: translateY(-50%);
  cursor: pointer;
}

.sidebar-toggle-button .fa {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  transition: all ease 0.25s;
}

.sidebar-toggle-button.open .fa {
  transform: translateY(-50%) rotate(-180deg);
}

.year-slider {
  width: 350px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .year-slider {
    display: none;
  }
}

.year-slider span.cat-title {
  position: absolute;
  top: 105px;
  width: 100%;
  display: block;
  left: 0;
  font-size: 18pt;
  font-weight: 500;
}

.year-slider.colour-white a {
  color: #FFFFFF;
}

.year-slider.colour-black a {
  color: #000000;
}

.year-slider.colour-dark-green a {
  color: #188d5f;
}

.year-slider.colour-green a {
  color: #77b957;
}

.year-slider.colour-orange a {
  color: #de6d3d;
}

.year-slider.colour-yellow a {
  color: #f8c453;
}

.year-slider.colour-pink a {
  color: #d44e8f;
}

.year-slider.colour-dark-blue a {
  color: #1f778e;
}

.year-slider.colour-light-blue a {
  color: #48c0de;
}

.year-slider.colour-purple a {
  color: #5a528f;
}

.year-slider.colour-grey a {
  color: #76787a;
}

.year-slider.colour-light-grey a {
  color: #F1F1F2;
}

.year-slider.colour-off-white a {
  color: #e7e7e7;
}

.year-slider.colour-cream a {
  color: #d4c3a3;
}

.year-slider.colour-before-people a {
  color: #188d5f;
}

.year-slider.colour-first-settlers a {
  color: #de6d3d;
}

.year-slider.colour-romans a {
  color: #48c0de;
}

.year-slider.colour-saxons-vikings-normans a {
  color: #f8c453;
}

.year-slider.colour-medieval a {
  color: #d44e8f;
}

.year-slider.colour-tudors-stuarts a {
  color: #1f778e;
}

.year-slider.colour-markets-to-railways a {
  color: #5a528f;
}

.year-slider.colour-brick-town-to-new-town a {
  color: #77b957;
}

@media only screen and (max-width: 1024px) {
  .year-slider {
    top: 35px;
  }
}

.year-slider ul li {
  font-size: 75px;
  font-weight: 500;
  width: 350px;
  height: 120px;
  opacity: 0.1;
  animation: 2s ease 0s normal forwards 1 fadein;
}

.year-slider ul li .label.text {
  transform: translate(-50%, -50%);
  display: inline-block;
  clear: both;
  float: left;
  white-space: nowrap;
  text-align: center;
  padding: 0;
  left: 50%;
  top: 50%;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .year-slider ul li {
    width: 250px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0.1;
  }
  66% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.story-card-buttons {
  width: 100%;
  background: #F1F1F2;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 50px;
  color: #76787a;
  position: absolute;
  bottom: 0;
  left: 0;
}

.story-card-buttons i {
  font-size: 2em;
  display: inline-block;
  vertical-align: middle;
}

.story-card-buttons span {
  margin-left: 10px;
  font-size: 11pt;
}

.story-card-buttons a, .story-card-buttons .main-button {
  color: #afafb5;
  font-weight: normal;
  position: relative;
  padding-right: 25px;
  margin-right: 15px;
  height: 100%;
}

.story-card-buttons a:after, .story-card-buttons .main-button:after {
  content: "";
  position: absolute;
  top: -10px;
  right: 0;
  height: 40px;
  width: 1px;
  border-left: 1px solid #b7b8b9;
}

.story-card-buttons a:last-of-type:after, .story-card-buttons .main-button:last-of-type:after {
  display: none;
}

.story-card-buttons a:Hover, .story-card-buttons .main-button:Hover {
  color: #444546;
  text-decoration: none;
}

.story-card-buttons a.liked, .story-card-buttons .main-button.liked {
  color: #F44336;
}

.story-card-content {
  padding: 20px;
  color: #76787a;
}

.story-card-content h3 {
  font-size: 24px;
  color: #77b957;
  margin-bottom: 1rem;
  line-height: 1.2;
  word-wrap: break-word;
}

.story-card-content a {
  color: #77b957;
}

.story-card-content p {
  color: #76787a;
  margin-bottom: 0;
  word-wrap: break-word;
}

@media only screen and (max-width: 992px) {
  .story-card-content {
    padding: 10px;
  }
  .story-card-content h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .story-card-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.story.card.colour-white .story-card-content h3, .story.card.colour-white .story-card-content a {
  color: #FFFFFF;
}

.story.card.colour-black .story-card-content h3, .story.card.colour-black .story-card-content a {
  color: #000000;
}

.story.card.colour-dark-green .story-card-content h3, .story.card.colour-dark-green .story-card-content a {
  color: #188d5f;
}

.story.card.colour-green .story-card-content h3, .story.card.colour-green .story-card-content a {
  color: #77b957;
}

.story.card.colour-orange .story-card-content h3, .story.card.colour-orange .story-card-content a {
  color: #de6d3d;
}

.story.card.colour-yellow .story-card-content h3, .story.card.colour-yellow .story-card-content a {
  color: #f8c453;
}

.story.card.colour-pink .story-card-content h3, .story.card.colour-pink .story-card-content a {
  color: #d44e8f;
}

.story.card.colour-dark-blue .story-card-content h3, .story.card.colour-dark-blue .story-card-content a {
  color: #1f778e;
}

.story.card.colour-light-blue .story-card-content h3, .story.card.colour-light-blue .story-card-content a {
  color: #48c0de;
}

.story.card.colour-purple .story-card-content h3, .story.card.colour-purple .story-card-content a {
  color: #5a528f;
}

.story.card.colour-grey .story-card-content h3, .story.card.colour-grey .story-card-content a {
  color: #76787a;
}

.story.card.colour-light-grey .story-card-content h3, .story.card.colour-light-grey .story-card-content a {
  color: #F1F1F2;
}

.story.card.colour-off-white .story-card-content h3, .story.card.colour-off-white .story-card-content a {
  color: #e7e7e7;
}

.story.card.colour-cream .story-card-content h3, .story.card.colour-cream .story-card-content a {
  color: #d4c3a3;
}

.story.card.colour-before-people .story-card-content h3, .story.card.colour-before-people .story-card-content a {
  color: #188d5f;
}

.story.card.colour-first-settlers .story-card-content h3, .story.card.colour-first-settlers .story-card-content a {
  color: #de6d3d;
}

.story.card.colour-romans .story-card-content h3, .story.card.colour-romans .story-card-content a {
  color: #48c0de;
}

.story.card.colour-saxons-vikings-normans .story-card-content h3, .story.card.colour-saxons-vikings-normans .story-card-content a {
  color: #f8c453;
}

.story.card.colour-medieval .story-card-content h3, .story.card.colour-medieval .story-card-content a {
  color: #d44e8f;
}

.story.card.colour-tudors-stuarts .story-card-content h3, .story.card.colour-tudors-stuarts .story-card-content a {
  color: #1f778e;
}

.story.card.colour-markets-to-railways .story-card-content h3, .story.card.colour-markets-to-railways .story-card-content a {
  color: #5a528f;
}

.story.card.colour-brick-town-to-new-town .story-card-content h3, .story.card.colour-brick-town-to-new-town .story-card-content a {
  color: #77b957;
}

.story.card.daily-card {
  text-align: left !important;
}

.story.card.daily-card .story-card-image {
  background-size: cover;
  background-image: url("../images/background.png");
}

.story.card.daily-card .date {
  position: absolute;
  top: calc(50% + 25px);
  left: 50%;
  width: 100%;
  color: #FFFFFF;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  font-size: 50px;
  line-height: 75px;
}

.story.card.daily-card .date span {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: -25px;
  margin-top: -25px;
}

.story.card .handle {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  padding-left: 10px;
}

.story-card-image {
  height: 225px;
  width: 100%;
  background: #F1F1F2;
  border-bottom: 7px solid #77b957;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: block;
}

.story-card-image img {
  height: auto;
  min-height: calc(100% + 3px);
  max-height: 120%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@media only screen and (max-width: 992px) {
  .story-card-image {
    height: 175px;
  }
}

.story.card.colour-white .story-card-image {
  border-color: #FFFFFF;
}

.story.card.colour-black .story-card-image {
  border-color: #000000;
}

.story.card.colour-dark-green .story-card-image {
  border-color: #188d5f;
}

.story.card.colour-green .story-card-image {
  border-color: #77b957;
}

.story.card.colour-orange .story-card-image {
  border-color: #de6d3d;
}

.story.card.colour-yellow .story-card-image {
  border-color: #f8c453;
}

.story.card.colour-pink .story-card-image {
  border-color: #d44e8f;
}

.story.card.colour-dark-blue .story-card-image {
  border-color: #1f778e;
}

.story.card.colour-light-blue .story-card-image {
  border-color: #48c0de;
}

.story.card.colour-purple .story-card-image {
  border-color: #5a528f;
}

.story.card.colour-grey .story-card-image {
  border-color: #76787a;
}

.story.card.colour-light-grey .story-card-image {
  border-color: #F1F1F2;
}

.story.card.colour-off-white .story-card-image {
  border-color: #e7e7e7;
}

.story.card.colour-cream .story-card-image {
  border-color: #d4c3a3;
}

.story.card.colour-before-people .story-card-image {
  border-color: #188d5f;
}

.story.card.colour-first-settlers .story-card-image {
  border-color: #de6d3d;
}

.story.card.colour-romans .story-card-image {
  border-color: #48c0de;
}

.story.card.colour-saxons-vikings-normans .story-card-image {
  border-color: #f8c453;
}

.story.card.colour-medieval .story-card-image {
  border-color: #d44e8f;
}

.story.card.colour-tudors-stuarts .story-card-image {
  border-color: #1f778e;
}

.story.card.colour-markets-to-railways .story-card-image {
  border-color: #5a528f;
}

.story.card.colour-brick-town-to-new-town .story-card-image {
  border-color: #77b957;
}

.story-card-locality-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50px !important;
  z-index: 1;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.5);
}

.story.card {
  width: 350px;
  background: #FFFFFF;
  border-radius: 1.2em;
  overflow: hidden;
  display: inline-block;
  margin: 0 auto;
  vertical-align: top;
  padding-bottom: 50px;
  position: relative;
  opacity: 0.1;
  cursor: pointer;
  animation: 2s ease 0s normal forwards 1 fadein;
}

@media only screen and (max-width: 992px) {
  .story.card {
    width: 280px;
    animation: none;
    opacity: 1;
  }
}

.story.card.add {
  height: 420px;
  cursor: pointer;
}

.story.card.add i {
  font-size: 108px;
  color: #76787a;
  opacity: 0.2;
  border: 8px dashed;
  border-radius: 50%;
  height: 180px;
  width: 180px;
  text-align: center;
  line-height: 165px;
  display: block;
  margin: 120px auto 0;
  font-style: normal;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.story.card.search-card {
  min-height: 450px;
  position: relative;
  cursor: pointer;
}

.story.card.search-card .mag {
  width: 200px;
  height: 200px;
  border-radius: 200px;
  position: absolute;
  top: 100px;
  left: 75px;
  line-height: 250px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.story.card.search-card .mag .fa {
  font-size: 100px;
}

.story.card.search-card .search-link {
  background: #FFFFFF;
  width: 70%;
  display: block;
  padding: 15px;
  position: absolute;
  top: 350px;
  left: 15%;
  text-align: center;
  border-radius: 20px;
}

.story-card-share-button {
  position: relative;
  padding-right: 0;
  display: inline-block;
}

.story-card-share-button .main-button {
  cursor: pointer;
}

.story-card-share-button .main-button:after {
  top: 5px;
}

.story-card-share-button .main-button:hover {
  color: #444546;
}

.story-card-share-button .share-box {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 0;
  width: 150px;
  height: auto;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding-top: 10px;
  padding-bottom: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  transition: all ease 0.15s;
}

.story-card-share-button .share-box.open {
  transform: scale(1);
}

.story-card-share-button .share-box .share-link {
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.story-card-share-button .share-box .share-link i.fab {
  font-size: 20px;
  transform: translateY(-1px);
  margin-right: 10px;
}

.story-card-share-button .share-box .share-link:hover {
  color: #FFFFFF;
}

.story-card-share-button .share-box .share-link.fb:hover {
  background: #3b5998;
}

.story-card-share-button .share-box .share-link.tw:hover {
  background: #00aced;
}

.story-card-share-button .share-box .share-link.gp:hover {
  background: #dd4b39;
}

.story-overlay .story-inner {
  text-align: left;
  width: 100%;
  padding-bottom: 50px;
  color: #FFFFFF;
  display: block;
  margin: 0 auto;
  position: relative;
  width: calc(100% - 200px);
}

@media only screen and (max-width: 1300px) {
  .story-overlay .story-inner {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .story-overlay .story-inner {
    height: 150%;
    overflow: scroll;
  }
  .story-overlay .story-inner .content {
    height: auto !important;
    overflow: scroll;
  }
}

.story-overlay .story-inner .content {
  height: 100%;
}

.story-overlay .story-inner .content section {
  width: 50%;
  display: inline-block;
  float: left;
  height: 100%;
}

@media only screen and (max-width: 992px) {
  .story-overlay .story-inner .content section {
    width: 100%;
    float: none;
    height: auto;
    display: block;
    padding-left: 0;
    margin-bottom: 30px;
  }
}

.story-overlay-information .para {
  max-height: 245px;
  overflow: auto;
  margin-bottom: 25px;
}

.story-overlay-information .para.colour-white .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #FFFFFF;
}

.story-overlay-information .para.colour-white .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: white;
}

.story-overlay-information .para.colour-black .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000000;
}

.story-overlay-information .para.colour-black .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #1a1919;
}

.story-overlay-information .para.colour-dark-green .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #188d5f;
}

.story-overlay-information .para.colour-dark-green .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #1fb97c;
}

.story-overlay-information .para.colour-green .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #77b957;
}

.story-overlay-information .para.colour-green .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #94c87b;
}

.story-overlay-information .para.colour-orange .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #de6d3d;
}

.story-overlay-information .para.colour-orange .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #e58e69;
}

.story-overlay-information .para.colour-yellow .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #f8c453;
}

.story-overlay-information .para.colour-yellow .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fad584;
}

.story-overlay-information .para.colour-pink .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #d44e8f;
}

.story-overlay-information .para.colour-pink .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #de77a9;
}

.story-overlay-information .para.colour-dark-blue .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #1f778e;
}

.story-overlay-information .para.colour-dark-blue .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #289ab8;
}

.story-overlay-information .para.colour-light-blue .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #48c0de;
}

.story-overlay-information .para.colour-light-blue .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #73cfe6;
}

.story-overlay-information .para.colour-purple .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #5a528f;
}

.story-overlay-information .para.colour-purple .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #736aaa;
}

.story-overlay-information .para.colour-grey .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #76787a;
}

.story-overlay-information .para.colour-grey .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #909293;
}

.story-overlay-information .para.colour-light-grey .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #F1F1F2;
}

.story-overlay-information .para.colour-light-grey .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: white;
}

.story-overlay-information .para.colour-off-white .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #e7e7e7;
}

.story-overlay-information .para.colour-off-white .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: white;
}

.story-overlay-information .para.colour-cream .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #d4c3a3;
}

.story-overlay-information .para.colour-cream .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #e4dac6;
}

.story-overlay-information .para.colour-before-people .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #188d5f;
}

.story-overlay-information .para.colour-before-people .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #1fb97c;
}

.story-overlay-information .para.colour-first-settlers .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #de6d3d;
}

.story-overlay-information .para.colour-first-settlers .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #e58e69;
}

.story-overlay-information .para.colour-romans .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #48c0de;
}

.story-overlay-information .para.colour-romans .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #73cfe6;
}

.story-overlay-information .para.colour-saxons-vikings-normans .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #f8c453;
}

.story-overlay-information .para.colour-saxons-vikings-normans .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fad584;
}

.story-overlay-information .para.colour-medieval .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #d44e8f;
}

.story-overlay-information .para.colour-medieval .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #de77a9;
}

.story-overlay-information .para.colour-tudors-stuarts .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #1f778e;
}

.story-overlay-information .para.colour-tudors-stuarts .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #289ab8;
}

.story-overlay-information .para.colour-markets-to-railways .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #5a528f;
}

.story-overlay-information .para.colour-markets-to-railways .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #736aaa;
}

.story-overlay-information .para.colour-brick-town-to-new-town .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #77b957;
}

.story-overlay-information .para.colour-brick-town-to-new-town .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #94c87b;
}

@media only screen and (max-width: 960px) {
  .story-overlay-information .para {
    max-height: unset;
  }
}

.story-overlay-map {
  width: 100%;
  height: 300px;
}

.related-links {
  width: calc(50% - 30px);
  margin-left: 30px;
  display: inline-block;
}

.related-links:after {
  content: "";
  display: table;
  clear: both;
}

.related-links ul {
  list-style-type: none;
  padding-left: 0;
}

.related-links ul li {
  width: 100%;
  height: auto;
  display: block;
  line-height: 1.4;
  padding-bottom: 2px;
}

.related-links ul li a {
  color: #FFFFFF;
  font-weight: 400;
}

.related-links ul li a:hover {
  text-decoration: underline;
}

.related-stories {
  width: 50%;
  display: inline-block;
  float: left;
}

.related-stories ul.related {
  box-sizing: border-box;
  list-style-type: none;
  padding-left: 0;
}

.related-stories ul.related li {
  width: 100%;
  height: auto;
  display: block;
  line-height: 1.4;
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.related-stories ul.related li a {
  color: #77b957;
}

.related-stories ul.related li a:Hover {
  text-decoration: underline;
}

.related-stories ul.related li span {
  font-weight: 400;
  display: block;
}

.related-stories ul.related li span.related-desc {
  color: #FFFFFF;
}

.related-stories ul.related li:last-of-type {
  border-bottom: 0;
}

.story-overlay-resources {
  box-sizing: border-box;
  padding-left: 50px;
}

.story-overlay-resources:after {
  content: "";
  display: table;
  clear: both;
}

.story-overlay-resources .other-resources:after {
  content: "";
  display: table;
  clear: both;
}

.resources-tags {
  display: block;
  width: 100%;
  float: none;
}

.resources-tags:after {
  content: "";
  display: table;
  clear: both;
}

.story-overlay-resources .files {
  max-height: 245px;
  overflow: auto;
  margin-bottom: 25px;
}

.story-overlay-resources .files .videos {
  display: block;
  width: 100%;
}

.story-overlay-resources .files .video-container {
  display: inline-block;
  width: calc(50% - 20px);
  position: relative;
}

.story-overlay-resources .files .video-container a.play-link {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #FFFFFF;
  font-size: 35px;
  z-index: 999;
  transform: translateX(-50%) translateY(-50%);
}

.story-overlay-resources .files video {
  width: 100%;
  border-radius: 3px;
  margin-right: 10px;
}

.story-overlay-resources .files .file {
  vertical-align: top;
  color: #FFFFFF;
  font-weight: 300;
  display: inline-block;
  float: left;
  width: 90px;
  background: transparent;
  height: 90px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  line-height: 90px;
  text-align: center;
  font-size: 50px;
  outline: none;
}

.story-overlay-resources .files .file img {
  border-radius: 3px;
  width: 90px;
  height: 90px;
}

.story-overlay-resources .files .file.file-audio {
  background: #77b957;
}

.story-overlay-resources .files .file.file-application {
  background: #5a528f;
}

.share-button {
  position: relative;
  padding-right: 25px;
  display: inline-block;
  margin-right: 15px;
  color: white;
}

.share-button .main-button {
  cursor: pointer;
}

.share-button a {
  margin-right: 7px;
}

.share-button:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  height: 30px;
  width: 1px;
  border-left: 1px solid #FFFFFF;
}

.share-button .share-box {
  display: inline-block;
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  width: 150px;
  height: auto;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding-top: 10px;
  padding-bottom: 10px;
  transform: scale(0);
  transform-origin: top left;
  transition: all ease 0.15s;
  color: #afafb5;
}

.share-button .share-box.open {
  transform: scale(1);
}

.share-button .share-box:hover {
  color: #afafb5;
}

.share-button .share-box .share-link {
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.share-button .share-box .share-link i.fab {
  font-size: 20px;
  transform: translateY(-1px);
  margin-right: 10px;
}

.share-button .share-box .share-link:hover {
  color: #FFFFFF;
}

.share-button .share-box .share-link.fb:hover {
  background: #3b5998;
}

.share-button .share-box .share-link.tw:hover {
  background: #00aced;
}

.share-button .share-box .share-link.gp:hover {
  background: #dd4b39;
}

.story-overlay-title {
  display: block;
  width: calc(100% - 50px);
}

.story-overlay-title h1 {
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  margin-right: 20px;
}

.story-overlay-title .other {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  width: auto;
  vertical-align: top;
  height: 30px;
  padding-right: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

.story-overlay-title .other a {
  color: #FFFFFF;
  font-weight: normal;
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  height: 100%;
}

.story-overlay-title .other a:after {
  content: "";
  position: absolute;
  top: -10px;
  right: 0;
  height: 40px;
  width: 1px;
  border-left: 1px solid #FFFFFF;
}

.story-overlay-title .other a:last-of-type:after {
  display: none;
}

.story-overlay-title .other a:Hover {
  color: #77b957;
  text-decoration: none;
}

.story-overlay-title .other a.liked {
  color: #F44336;
}

.story-overlay-title .other i {
  font-size: 1.5em;
  display: inline-block;
  vertical-align: middle;
}

.story-overlay-title .other span {
  margin-left: 10px;
  font-size: 11pt;
}

h2.story-overlay-year {
  color: #FFFFFF !important;
  display: block;
  margin-top: 25px;
}

.overlay.add-story h2 {
  font-size: 50px;
  margin: 100px 0 80px;
}

.overlay.add-story .link-blocks img {
  max-width: 60%;
  height: auto;
  margin-top: 40px;
}

.overlay.add-story .link-blocks h3 {
  font-size: 35px;
}

.overlay.add-story .link-blocks .col {
  padding-top: 35px;
}

.overlay.add-story .link-blocks .sep span {
  position: relative;
  padding-top: 40px;
  display: block;
  height: 100%;
  text-align: center;
  font-size: 35px;
  font-weight: 400;
}

.overlay.add-story .link-blocks .sep span:before {
  content: '';
  position: absolute;
  top: 0;
  height: 30px;
  width: 3px;
  background: #77b957;
  left: 50%;
}

.overlay.add-story .link-blocks .sep span:after {
  content: '';
  position: absolute;
  bottom: 0;
  height: calc(100% - 85px);
  width: 3px;
  background: #77b957;
  left: 50%;
}

.card-slider.colour-white .flipster__button svg {
  stroke: #FFFFFF;
}

.card-slider.colour-white .flipster__button:hover svg,
.card-slider.colour-white .flipster__button:focus svg {
  stroke: #FFFFFF;
}

.card-slider.colour-black .flipster__button svg {
  stroke: #000000;
}

.card-slider.colour-black .flipster__button:hover svg,
.card-slider.colour-black .flipster__button:focus svg {
  stroke: #000000;
}

.card-slider.colour-dark-green .flipster__button svg {
  stroke: #188d5f;
}

.card-slider.colour-dark-green .flipster__button:hover svg,
.card-slider.colour-dark-green .flipster__button:focus svg {
  stroke: #188d5f;
}

.card-slider.colour-green .flipster__button svg {
  stroke: #77b957;
}

.card-slider.colour-green .flipster__button:hover svg,
.card-slider.colour-green .flipster__button:focus svg {
  stroke: #77b957;
}

.card-slider.colour-orange .flipster__button svg {
  stroke: #de6d3d;
}

.card-slider.colour-orange .flipster__button:hover svg,
.card-slider.colour-orange .flipster__button:focus svg {
  stroke: #de6d3d;
}

.card-slider.colour-yellow .flipster__button svg {
  stroke: #f8c453;
}

.card-slider.colour-yellow .flipster__button:hover svg,
.card-slider.colour-yellow .flipster__button:focus svg {
  stroke: #f8c453;
}

.card-slider.colour-pink .flipster__button svg {
  stroke: #d44e8f;
}

.card-slider.colour-pink .flipster__button:hover svg,
.card-slider.colour-pink .flipster__button:focus svg {
  stroke: #d44e8f;
}

.card-slider.colour-dark-blue .flipster__button svg {
  stroke: #1f778e;
}

.card-slider.colour-dark-blue .flipster__button:hover svg,
.card-slider.colour-dark-blue .flipster__button:focus svg {
  stroke: #1f778e;
}

.card-slider.colour-light-blue .flipster__button svg {
  stroke: #48c0de;
}

.card-slider.colour-light-blue .flipster__button:hover svg,
.card-slider.colour-light-blue .flipster__button:focus svg {
  stroke: #48c0de;
}

.card-slider.colour-purple .flipster__button svg {
  stroke: #5a528f;
}

.card-slider.colour-purple .flipster__button:hover svg,
.card-slider.colour-purple .flipster__button:focus svg {
  stroke: #5a528f;
}

.card-slider.colour-grey .flipster__button svg {
  stroke: #76787a;
}

.card-slider.colour-grey .flipster__button:hover svg,
.card-slider.colour-grey .flipster__button:focus svg {
  stroke: #76787a;
}

.card-slider.colour-light-grey .flipster__button svg {
  stroke: #F1F1F2;
}

.card-slider.colour-light-grey .flipster__button:hover svg,
.card-slider.colour-light-grey .flipster__button:focus svg {
  stroke: #F1F1F2;
}

.card-slider.colour-off-white .flipster__button svg {
  stroke: #e7e7e7;
}

.card-slider.colour-off-white .flipster__button:hover svg,
.card-slider.colour-off-white .flipster__button:focus svg {
  stroke: #e7e7e7;
}

.card-slider.colour-cream .flipster__button svg {
  stroke: #d4c3a3;
}

.card-slider.colour-cream .flipster__button:hover svg,
.card-slider.colour-cream .flipster__button:focus svg {
  stroke: #d4c3a3;
}

.card-slider.colour-before-people .flipster__button svg {
  stroke: #188d5f;
}

.card-slider.colour-before-people .flipster__button:hover svg,
.card-slider.colour-before-people .flipster__button:focus svg {
  stroke: #188d5f;
}

.card-slider.colour-first-settlers .flipster__button svg {
  stroke: #de6d3d;
}

.card-slider.colour-first-settlers .flipster__button:hover svg,
.card-slider.colour-first-settlers .flipster__button:focus svg {
  stroke: #de6d3d;
}

.card-slider.colour-romans .flipster__button svg {
  stroke: #48c0de;
}

.card-slider.colour-romans .flipster__button:hover svg,
.card-slider.colour-romans .flipster__button:focus svg {
  stroke: #48c0de;
}

.card-slider.colour-saxons-vikings-normans .flipster__button svg {
  stroke: #f8c453;
}

.card-slider.colour-saxons-vikings-normans .flipster__button:hover svg,
.card-slider.colour-saxons-vikings-normans .flipster__button:focus svg {
  stroke: #f8c453;
}

.card-slider.colour-medieval .flipster__button svg {
  stroke: #d44e8f;
}

.card-slider.colour-medieval .flipster__button:hover svg,
.card-slider.colour-medieval .flipster__button:focus svg {
  stroke: #d44e8f;
}

.card-slider.colour-tudors-stuarts .flipster__button svg {
  stroke: #1f778e;
}

.card-slider.colour-tudors-stuarts .flipster__button:hover svg,
.card-slider.colour-tudors-stuarts .flipster__button:focus svg {
  stroke: #1f778e;
}

.card-slider.colour-markets-to-railways .flipster__button svg {
  stroke: #5a528f;
}

.card-slider.colour-markets-to-railways .flipster__button:hover svg,
.card-slider.colour-markets-to-railways .flipster__button:focus svg {
  stroke: #5a528f;
}

.card-slider.colour-brick-town-to-new-town .flipster__button svg {
  stroke: #77b957;
}

.card-slider.colour-brick-town-to-new-town .flipster__button:hover svg,
.card-slider.colour-brick-town-to-new-town .flipster__button:focus svg {
  stroke: #77b957;
}

@media only screen and (max-width: 992px) {
  .main-content #slider {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .main-content #slider li {
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
  }
  .main-content #slider li:first-of-type {
    margin-left: 0;
  }
}

.flipster ul {
  list-style-type: none !important;
}

.year-title {
  display: none;
}

@media only screen and (max-width: 992px) {
  .year-title {
    display: block;
    font-size: 24px;
    max-width: 100%;
  }
}

ul.time-bar {
  list-style-type: none;
  height: 25px;
  width: auto;
  display: table;
  margin: 0 auto;
  position: relative;
  margin-top: 60px;
  margin-bottom: 50px;
}

ul.time-bar.colour-white li {
  background: #FFFFFF;
}

ul.time-bar.colour-white .indicator:after {
  color: #FFFFFF;
}

ul.time-bar.colour-black li {
  background: #000000;
}

ul.time-bar.colour-black .indicator:after {
  color: #000000;
}

ul.time-bar.colour-dark-green li {
  background: #188d5f;
}

ul.time-bar.colour-dark-green .indicator:after {
  color: #188d5f;
}

ul.time-bar.colour-green li {
  background: #77b957;
}

ul.time-bar.colour-green .indicator:after {
  color: #77b957;
}

ul.time-bar.colour-orange li {
  background: #de6d3d;
}

ul.time-bar.colour-orange .indicator:after {
  color: #de6d3d;
}

ul.time-bar.colour-yellow li {
  background: #f8c453;
}

ul.time-bar.colour-yellow .indicator:after {
  color: #f8c453;
}

ul.time-bar.colour-pink li {
  background: #d44e8f;
}

ul.time-bar.colour-pink .indicator:after {
  color: #d44e8f;
}

ul.time-bar.colour-dark-blue li {
  background: #1f778e;
}

ul.time-bar.colour-dark-blue .indicator:after {
  color: #1f778e;
}

ul.time-bar.colour-light-blue li {
  background: #48c0de;
}

ul.time-bar.colour-light-blue .indicator:after {
  color: #48c0de;
}

ul.time-bar.colour-purple li {
  background: #5a528f;
}

ul.time-bar.colour-purple .indicator:after {
  color: #5a528f;
}

ul.time-bar.colour-grey li {
  background: #76787a;
}

ul.time-bar.colour-grey .indicator:after {
  color: #76787a;
}

ul.time-bar.colour-light-grey li {
  background: #F1F1F2;
}

ul.time-bar.colour-light-grey .indicator:after {
  color: #F1F1F2;
}

ul.time-bar.colour-off-white li {
  background: #e7e7e7;
}

ul.time-bar.colour-off-white .indicator:after {
  color: #e7e7e7;
}

ul.time-bar.colour-cream li {
  background: #d4c3a3;
}

ul.time-bar.colour-cream .indicator:after {
  color: #d4c3a3;
}

ul.time-bar.colour-before-people li {
  background: #188d5f;
}

ul.time-bar.colour-before-people .indicator:after {
  color: #188d5f;
}

ul.time-bar.colour-first-settlers li {
  background: #de6d3d;
}

ul.time-bar.colour-first-settlers .indicator:after {
  color: #de6d3d;
}

ul.time-bar.colour-romans li {
  background: #48c0de;
}

ul.time-bar.colour-romans .indicator:after {
  color: #48c0de;
}

ul.time-bar.colour-saxons-vikings-normans li {
  background: #f8c453;
}

ul.time-bar.colour-saxons-vikings-normans .indicator:after {
  color: #f8c453;
}

ul.time-bar.colour-medieval li {
  background: #d44e8f;
}

ul.time-bar.colour-medieval .indicator:after {
  color: #d44e8f;
}

ul.time-bar.colour-tudors-stuarts li {
  background: #1f778e;
}

ul.time-bar.colour-tudors-stuarts .indicator:after {
  color: #1f778e;
}

ul.time-bar.colour-markets-to-railways li {
  background: #5a528f;
}

ul.time-bar.colour-markets-to-railways .indicator:after {
  color: #5a528f;
}

ul.time-bar.colour-brick-town-to-new-town li {
  background: #77b957;
}

ul.time-bar.colour-brick-town-to-new-town .indicator:after {
  color: #77b957;
}

@media only screen and (max-width: 992px) {
  ul.time-bar {
    padding-left: 5px;
    width: calc(100% - 10px);
  }
}

@media only screen and (max-width: 992px) {
  ul.time-bar {
    display: none;
  }
}

ul.time-bar .blocks {
  display: inline-block;
  float: left;
  position: relative;
}

@media only screen and (max-width: 768px) {
  ul.time-bar .blocks {
    left: 50%;
    transform: translateX(-50%);
  }
}

ul.time-bar .blocks-percent {
  opacity: 0;
  width: calc(100% - 340px);
  position: absolute;
  top: 0;
  left: 190px;
  z-index: 999;
  display: flex;
}

ul.time-bar .blocks-percent li {
  flex-grow: 1;
}

@media only screen and (max-width: 1024px) {
  ul.time-bar .blocks-percent {
    width: calc(100% - 100px);
    left: 50px;
  }
}

.indicator {
  display: block;
  position: absolute;
  left: 0px;
  top: 3px;
  width: 10px;
  height: 20px;
  transition: all linear 0.2s;
  z-index: 99;
  cursor: ew-resize;
}

.indicator:before {
  content: "";
  border-style: solid;
  border-width: 7px 10px 7px 0;
  transform-origin: center center;
  transform: scale(1) rotate(90deg);
  border-color: transparent #FFFFFF transparent transparent;
  position: absolute;
  left: calc(50% - 5px);
  top: 15px;
  z-index: 999;
  transition: all ease 0.25s;
}

.indicator:after {
  content: "\f053        \f054";
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  display: block;
  width: 50px;
  height: 25px;
  background: #FFFFFF;
  position: absolute;
  top: 27px;
  z-index: 99;
  left: -20px;
  color: #77b957;
  text-align: center;
  font-size: 12pt;
  line-height: 27px;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.09);
  transition: all ease 0.25s;
}

@media only screen and (max-width: 992px) {
  .indicator {
    display: none;
  }
}

ul.time-bar li {
  cursor: pointer;
  width: 18px;
  height: 5px;
  background: #77b957;
  display: inline-block;
  float: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  position: relative;
}

@media only screen and (max-width: 992px) {
  ul.time-bar li {
    width: calc(1.5vw);
  }
}

ul.time-bar li:first-of-type {
  margin-left: 0px;
  height: 30px;
  width: 5px;
  margin-top: -3px;
}

ul.time-bar li:last-of-type {
  margin-right: 0px;
  height: 30px;
  margin-top: -3px;
  width: 5px;
}

ul.time-bar span.first-year, ul.time-bar span.last-year {
  text-align: center;
  width: 100px;
  display: inline-block;
  float: left;
  height: 25px;
  font-size: 15pt;
  font-weight: 500;
  line-height: 23px;
  margin-right: 20px;
  position: relative;
}

ul.time-bar span.first-year > span, ul.time-bar span.last-year > span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1024px) {
  ul.time-bar span.first-year, ul.time-bar span.last-year {
    display: none;
  }
}

ul.time-bar span.last-year {
  margin-left: 20px;
  margin-right: 0;
}

.story-filter-no-results {
  text-align: center;
  margin-top: 100px;
}

.story-filter-no-results .btn {
  display: block;
  margin: 0 auto;
  width: 100%;
  font-size: 1.2rem;
  max-width: 250px;
}

.create-story .map {
  height: 500px;
  width: 100%;
}

.quiz-card-answer-item {
  padding-left: 20px;
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.quiz-card-answer-item:last-of-type {
  margin-bottom: 0;
}

.quiz-card-answer-item label {
  margin-bottom: 0;
}

.quiz-card-answer-item label span {
  color: #76787a;
  font-weight: normal;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.quiz-card-answer-item label input[type=radio] {
  position: absolute;
  visibility: hidden;
}

.quiz-card-answer-item label input[type=radio]:checked ~ .check {
  border: 2px solid #77b957;
  background: #77b957;
}

.quiz-card-answer-item label .check {
  display: block;
  position: absolute;
  border: 1px solid #77b957;
  border-radius: 100%;
  height: 17px;
  width: 17px;
  top: 50%;
  left: 0;
  z-index: 5;
  transform: translateY(-50%);
  transition: all ease 0.25s;
}

.quiz-card-answer-item:last-of-type {
  margin-bottom: 0;
}

.quiz-card-answer-item:hover .check {
  border: 2px solid #77b957;
}

.quiz-card-answers {
  padding: 20px;
}

.quiz-card-answers .list {
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.quiz-card-correct-overlay {
  height: 0;
  overflow: hidden;
  background: #FFFFFF;
}

.quiz-card-correct-overlay .header {
  background: #77b957;
  padding: 20px;
  color: #FFFFFF;
}

.quiz-card-correct-overlay .header i.fa {
  font-size: 75px;
  display: inline-block;
  vertical-align: bottom;
}

.quiz-card-correct-overlay .header h3 {
  display: inline-block;
  color: #FFFFFF;
}

.quiz-card-correct-overlay .card-content {
  color: #76787a;
  padding: 20px;
}

.quiz-card-correct-overlay .card-content p {
  border-bottom: 2px solid #76787a;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.quiz-card-correct-overlay .card-content p:first-of-type {
  border: 0;
}

.quiz-card-correct-overlay .card-content p:last-of-type {
  border: 0;
}

.quiz-card-incorrect-overlay {
  height: 0;
  overflow: hidden;
}

.quiz-card-incorrect-overlay .header {
  background: #e36e39;
  padding: 20px;
  color: #FFFFFF;
}

.quiz-card-incorrect-overlay .header i.fa {
  font-size: 75px;
  display: inline-block;
  vertical-align: bottom;
}

.quiz-card-incorrect-overlay .header h3 {
  display: inline-block;
  color: #FFFFFF;
}

.quiz-card-incorrect-overlay .answers {
  padding-top: 0;
}

.quiz-card-incorrect-overlay .card-content {
  color: #76787a;
}

.quiz-card-incorrect-overlay .card-content p:first-of-type {
  padding: 20px;
  margin-bottom: 0;
}

.quiz.card {
  width: 350px;
  background: #FFFFFF;
  border-radius: 1.2em;
  overflow: hidden;
  display: inline-block;
  margin: 0 auto;
  vertical-align: top;
  position: relative;
  max-height: 500px;
  min-height: 420px;
}

.quiz.card .content {
  height: auto;
}

.quiz.card.incorrect .content, .quiz.card.correct .content {
  height: 0;
  overflow: hidden;
}

.quiz.card.incorrect .quiz-card-incorrect-overlay {
  height: auto;
}

.quiz.card.correct .quiz-card-correct-overlay {
  height: auto;
}

.quiz-card-question {
  padding: 20px;
  border-bottom: 7px solid #77b957;
}

.quiz-card-question h3 {
  color: #77b957;
  margin-bottom: 1rem;
}

.quiz-card-question p {
  color: #76787a;
  margin-bottom: 0;
}

#page-home .categories-navigation {
  display: block;
}

#page-home .main-content {
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 200px;
}

@media only screen and (max-width: 992px) {
  #page-home .main-content {
    margin-top: 0;
    margin-bottom: 50px;
  }
}

#page-home .main-content .btn.start-here {
  display: block;
  margin: 0 auto;
  width: 100%;
  font-size: 1.2rem;
  max-width: 250px;
}

#page-home .main-content .wrapper.has-card {
  margin-top: -30px;
}

#page-home .main-content .wrapper.has-card .text {
  width: 55%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

#page-home .main-content .wrapper.has-card .text h1 {
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 50px;
}

@media only screen and (max-width: 992px) {
  #page-home .main-content .wrapper.has-card .text h1 {
    margin-bottom: 10px;
  }
}

#page-home .main-content .wrapper.has-card .text .button {
  padding: .4em 3.5em;
}

#page-home .main-content .wrapper.has-card .card-container {
  width: 42%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

#page-home .main-content .wrapper.has-card .card-container .card {
  text-align: left;
}

#page-single .content, #page-single .content p {
  font-weight: 100;
}

#category-single .categories-navigation, #story-single .categories-navigation {
  display: block;
  margin-top: 60px;
}

#category-single body .main-content {
  overflow: hidden;
}

#create-an-account-page {
  text-align: center;
}

#create-an-account-page .row {
  max-width: 900px;
  max-width: 900px;
  margin: 0 auto;
}

#create-an-account-page .box {
  border-radius: 10px;
  border: 2px solid #FFFFFF;
  padding: 30px;
  text-align: left;
}

#create-an-account-page .box .btn {
  display: block;
  max-width: 250px;
  margin: 0 auto;
}

#select-a-story .main-content {
  margin-top: 10px;
}

@media only screen and (max-width: 1024px) {
  #select-a-story .categories-sidebar h3 {
    font-size: 18px;
  }
}

#select-a-story .categories-sidebar ul {
  list-style-type: none;
  padding-left: 0px;
}

#select-a-story .categories-sidebar ul li.category-item {
  list-style-type: none;
  display: block;
  width: 100%;
}

#select-a-story .categories-sidebar ul li.category-item label {
  margin-bottom: 0;
}

#select-a-story .categories-sidebar ul li.category-item label span a {
  color: #FFFFFF;
}

#select-a-story .categories-sidebar ul li.category-item:after {
  content: "";
  display: table;
  clear: both;
}

#select-a-story .no-results-text {
  display: none;
}

#select-a-story .story-card-mini {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

#select-a-story .story-card-mini .image-container {
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background: #FFFFFF;
}

#select-a-story .story-card-mini .image-container img {
  width: calc(100% + 2px);
  max-width: calc(100% + 2px);
  height: auto;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% + 1px);
  transform: translateY(-50%) translateX(-50%);
}

#select-a-story .story-card-mini .image-container:before, #select-a-story .story-card-mini .image-container:after {
  transition: all ease 0.2s;
}

#select-a-story .story-card-mini .image-container:after {
  opacity: 0;
  z-index: 3;
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: #FFFFFF;
  font-size: 50px;
  font-weight: bold;
  transform-origin: top left;
}

#select-a-story .story-card-mini .image-container:before {
  opacity: 0;
  z-index: 2;
  border-radius: 10px 10px 0 0;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100%);
  height: calc(100%);
  background: rgba(119, 185, 87, 0.75);
}

#select-a-story .story-card-mini .text-container {
  color: #76787a;
  font-size: 18px;
  font-weight: 400;
  background: #FFFFFF;
  border-radius: 0 0 10px 10px;
  padding: 10px;
  margin-top: -2px;
}

#select-a-story .story-card-mini:hover .image-container:before, #select-a-story .story-card-mini:hover .image-container:after {
  opacity: 1;
}

#select-a-story .story-card-mini.processing .image-container:before {
  opacity: 1;
}

#select-a-story .story-card-mini.processing .image-container:after {
  opacity: 1;
  content: "\f3f4";
  font-family: "Font Awesome 5 Pro";
  animation: rotate 1s infinite linear;
}

#select-a-story .story-card-mini.colour-white .text-container {
  color: #FFFFFF;
}

#select-a-story .story-card-mini.colour-black .text-container {
  color: #000000;
}

#select-a-story .story-card-mini.colour-dark-green .text-container {
  color: #188d5f;
}

#select-a-story .story-card-mini.colour-green .text-container {
  color: #77b957;
}

#select-a-story .story-card-mini.colour-orange .text-container {
  color: #de6d3d;
}

#select-a-story .story-card-mini.colour-yellow .text-container {
  color: #f8c453;
}

#select-a-story .story-card-mini.colour-pink .text-container {
  color: #d44e8f;
}

#select-a-story .story-card-mini.colour-dark-blue .text-container {
  color: #1f778e;
}

#select-a-story .story-card-mini.colour-light-blue .text-container {
  color: #48c0de;
}

#select-a-story .story-card-mini.colour-purple .text-container {
  color: #5a528f;
}

#select-a-story .story-card-mini.colour-grey .text-container {
  color: #76787a;
}

#select-a-story .story-card-mini.colour-light-grey .text-container {
  color: #F1F1F2;
}

#select-a-story .story-card-mini.colour-off-white .text-container {
  color: #e7e7e7;
}

#select-a-story .story-card-mini.colour-cream .text-container {
  color: #d4c3a3;
}

#select-a-story .story-card-mini.colour-before-people .text-container {
  color: #188d5f;
}

#select-a-story .story-card-mini.colour-first-settlers .text-container {
  color: #de6d3d;
}

#select-a-story .story-card-mini.colour-romans .text-container {
  color: #48c0de;
}

#select-a-story .story-card-mini.colour-saxons-vikings-normans .text-container {
  color: #f8c453;
}

#select-a-story .story-card-mini.colour-medieval .text-container {
  color: #d44e8f;
}

#select-a-story .story-card-mini.colour-tudors-stuarts .text-container {
  color: #1f778e;
}

#select-a-story .story-card-mini.colour-markets-to-railways .text-container {
  color: #5a528f;
}

#select-a-story .story-card-mini.colour-brick-town-to-new-town .text-container {
  color: #77b957;
}

#select-a-story .pagination {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

#select-a-story .pagination li {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 2px;
  background: transparent;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  text-align: center;
  line-height: 32px;
}

#select-a-story .pagination li.active, #select-a-story .pagination li.disabled {
  cursor: not-allowed;
}

#select-a-story .pagination li.active:hover, #select-a-story .pagination li.disabled:hover {
  background: transparent;
  color: #FFFFFF;
}

#select-a-story .pagination li:hover {
  background: #FFFFFF;
  color: #76787a;
}

#select-a-story .pagination li:hover a {
  color: #76787a;
}

#select-a-story .pagination li.active {
  background: #FFFFFF;
  color: #76787a;
}

#select-a-story .pagination li.active:hover {
  background: #FFFFFF;
  color: #76787a;
}

#select-a-story .pagination li a {
  height: 100%;
  width: 100%;
  display: block;
}

#select-a-story .pagination li a:hover {
  color: #77b957;
}

@keyframes rotate {
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}

#public-dashboard .link-blocks,
#teacher-dashboard .link-blocks {
  margin-top: 120px;
}

#public-dashboard .link-blocks img,
#teacher-dashboard .link-blocks img {
  width: auto;
  height: 150px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

#public-dashboard .premium-only,
#teacher-dashboard .premium-only {
  opacity: 0.3;
}

#public-dashboard .premium-only img, #public-dashboard .premium-only a, #public-dashboard .premium-only h3,
#teacher-dashboard .premium-only img,
#teacher-dashboard .premium-only a,
#teacher-dashboard .premium-only h3 {
  opacity: 0.3;
}

#public-dashboard .bottom-links,
#teacher-dashboard .bottom-links {
  position: absolute;
  bottom: 20px;
  right: 0;
}

@media only screen and (max-width: 992px) {
  #public-dashboard .bottom-links,
  #teacher-dashboard .bottom-links {
    position: static;
    margin-bottom: 75px;
  }
}

#public-dashboard .bottom-links .bottom-link,
#teacher-dashboard .bottom-links .bottom-link {
  width: 150px;
  text-align: center;
}

#public-dashboard .bottom-links .bottom-link.wider,
#teacher-dashboard .bottom-links .bottom-link.wider {
  width: 250px;
  padding-top: 30px;
}

#public-dashboard .bottom-links .bottom-link.border-right,
#teacher-dashboard .bottom-links .bottom-link.border-right {
  border-right: 2px solid #FFFFFF;
}

#public-dashboard .bottom-links .bottom-link .image-container,
#teacher-dashboard .bottom-links .bottom-link .image-container {
  width: 100%;
  height: 70px;
  position: relative;
}

#public-dashboard .bottom-links .bottom-link .image-container img,
#teacher-dashboard .bottom-links .bottom-link .image-container img {
  width: auto;
  height: 50px;
  max-width: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#public-dashboard .bottom-links .bottom-link h6,
#teacher-dashboard .bottom-links .bottom-link h6 {
  font-weight: normal;
  line-height: 1.3;
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  #public-dashboard .main-content,
  #teacher-dashboard .main-content {
    margin-top: 0 !important;
    padding-left: 30px;
    padding-right: 30px;
  }
  #public-dashboard .link-blocks,
  #teacher-dashboard .link-blocks {
    margin-top: 20px !important;
  }
  #public-dashboard .link-blocks img,
  #teacher-dashboard .link-blocks img {
    height: 50px !important;
  }
  #public-dashboard .link-blocks h3,
  #teacher-dashboard .link-blocks h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

#teacher-dashboard .link-blocks img {
  height: 120px;
}

#liked-stories .stories-list {
  max-width: 750px;
  width: 95%;
  display: block;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  margin-top: 100px;
  max-height: 300px;
  overflow: hidden;
  overflow-x: hidden;
}

#liked-stories .stories-list li {
  list-style-type: none;
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #FFFFFF;
}

#liked-stories .stories-list li:last-of-type {
  border-bottom: 0;
}

#liked-stories .stories-list li .buttons {
  width: 100%;
  color: #FFFFFF;
}

#liked-stories .stories-list li .buttons a, #liked-stories .stories-list li .buttons .main-button {
  color: white;
  font-weight: normal;
  position: relative;
  padding-right: 25px;
  margin-right: 15px;
  height: 100%;
  font-size: 10px;
}

#liked-stories .stories-list li .buttons a:after, #liked-stories .stories-list li .buttons .main-button:after {
  content: "";
  position: absolute;
  top: -7px;
  right: 0;
  height: 25px;
  width: 1px;
}

#liked-stories .stories-list li .buttons a:last-of-type:after, #liked-stories .stories-list li .buttons .main-button:last-of-type:after {
  display: none;
}

#liked-stories .stories-list li .buttons a:Hover, #liked-stories .stories-list li .buttons .main-button:Hover {
  color: #cccccc;
  text-decoration: none;
}

#liked-stories .stories-list li .buttons a.liked, #liked-stories .stories-list li .buttons .main-button.liked {
  color: #F44336;
}

#liked-stories .stories-list li .buttons .like {
  margin-right: 0;
  padding-right: 0;
}

#liked-stories .stories-list li .buttons i {
  font-size: 2em;
  display: inline-block;
  vertical-align: middle;
}

#liked-stories .stories-list li .buttons span {
  margin-left: 10px;
  font-size: 11pt;
}

#liked-stories .stories-list li .buttons .share-button {
  position: relative;
  padding-right: 0;
  display: inline-block;
}

#liked-stories .stories-list li .buttons .share-button .main-button {
  margin-right: 0px;
  cursor: pointer;
}

#liked-stories .stories-list li .buttons .share-button .main-button:after {
  top: 2px;
}

#liked-stories .stories-list li .buttons .share-button .main-button:hover {
  color: #cccccc;
}

#liked-stories .stories-list li .buttons .share-button .share-box {
  position: absolute;
  top: calc(100% + 15px);
  right: 15px;
  width: 175px;
  text-align: left;
  height: auto;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding-top: 10px;
  padding-bottom: 10px;
  transform: scale(0);
  transform-origin: top right;
  transition: all ease 0.15s;
  z-index: 99;
}

#liked-stories .stories-list li .buttons .share-button .share-box.open {
  transform: scale(1);
}

#liked-stories .stories-list li .buttons .share-button .share-box .share-link {
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  color: #76787a;
}

#liked-stories .stories-list li .buttons .share-button .share-box .share-link i.fab {
  font-size: 20px;
  transform: translateY(-1px);
  margin-right: 10px;
}

#liked-stories .stories-list li .buttons .share-button .share-box .share-link:hover {
  color: #FFFFFF;
}

#liked-stories .stories-list li .buttons .share-button .share-box .share-link.fb:hover {
  background: #3b5998;
}

#liked-stories .stories-list li .buttons .share-button .share-box .share-link.tw:hover {
  background: #00aced;
}

#liked-stories .stories-list li .buttons .share-button .share-box .share-link.gp:hover {
  background: #dd4b39;
}

#premium-holding .main-content {
  margin-top: 0;
}

#premium-holding .title {
  text-align: center;
  margin-bottom: 100px;
}

#premium-holding .content {
  max-width: 900px;
  width: 90%;
  display: block;
  margin: 0 auto;
}

#premium-holding .content h1, #premium-holding .content h2, #premium-holding .content h3, #premium-holding .content h4, #premium-holding .content h5, #premium-holding .content h6 {
  color: #d44e8f;
}

#welcome-page .content {
  max-width: 650px;
  display: block;
  margin: 0 auto;
  width: 95%;
}

#welcome-page .content .btn {
  width: 200px;
}

#submit-a-story .main-content, #request-story .main-content {
  margin-top: 0;
}

#submit-a-story .title, #request-story .title {
  margin-bottom: 100px;
}

#login-page form .note a {
  color: #FFFFFF;
  font-size: 14px;
}

#login-page form .note a:hover {
  text-decoration: underline;
}

#register-page h1 {
  margin-bottom: 0;
}

#register-page h3 {
  margin-bottom: 20px;
}

#register-page form .note a {
  color: #FFFFFF;
  font-size: 14px;
}

#register-page form .note a:hover {
  text-decoration: underline;
}

#search-results .categories-navigation {
  display: block;
}

.timeline-list {
  float: left;
  width: 100%;
  padding-bottom: 50px;
}

.timeline-list .timeline {
  position: relative;
  height: 420px;
  width: 450px;
  float: left;
  padding-left: 50px;
  margin-bottom: 50px;
}

.timeline-list .timeline .timeline-link {
  position: absolute;
  top: 0;
  left: 0;
  height: 110%;
  width: 100%;
  z-index: 10;
}

@media only screen and (max-width: 1024px) {
  .timeline-list .timeline .timeline-link {
    width: 85%w;
    height: 100%;
  }
}

.timeline-list .timeline .timeline-link h3 {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .timeline-list .timeline .timeline-link h3 {
    font-size: 20px;
  }
}

.timeline-list .timeline .timeline-link:hover {
  color: #188d5f;
}

.timeline-list .timeline li {
  list-style: none;
  position: absolute;
  z-index: 2;
}

.timeline-list .timeline li:nth-child(1) {
  transform: scale(0.8);
  z-index: 3;
}

.timeline-list .timeline li:nth-child(2) {
  transform: rotate(10deg) translate(60px, -20px) scale(0.7);
}

.timeline-list .timeline li:nth-child(3) {
  transform: rotate(-10deg) translate(-60px, -20px) scale(0.7);
}

.btn.create-timeline {
  background: #77b957;
  float: right;
  width: auto;
  padding: 12px 20px;
  font-size: 18px;
  color: #FFFFFF;
}

form.create-story button.btn {
  background: #77b957;
  padding: 12px 20px;
  font-size: 18px;
  color: #FFFFFF;
  margin-top: 30px;
}

.edit-timeline .story-placeholder {
  background: rgba(255, 255, 255, 0.2);
  border: 1px dashed rgba(255, 255, 255, 0.4);
  height: 64px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.edit-timeline .stories {
  position: relative;
}

.edit-timeline .story {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 1.5rem 0;
}

.edit-timeline .media-body h5 .fas {
  float: right;
  cursor: grab;
}

.edit-timeline .media-body h5 .fas:active {
  cursor: grabbing;
}
