.story-overlay-resources .files {
    .file {
        vertical-align: top;
        color: $white;
        font-weight: 300;
        display: inline-block;
        float: left;
        width: 90px;
        background: transparent;
        height: 90px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 3px;
        line-height: 90px;
        text-align: center;
        font-size: 50px;
        outline: none;
        img {
            border-radius: 3px;
            width: 90px;
            height: 90px;
        }
        &.file-audio {
            background: $green;
        }
        &.file-application {
            background: $purple;
        }
    }
}