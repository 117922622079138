.btn {
    font-weight: bold;
    padding: 12px 10px;
    font-size: 18px;
    line-height: 18px;
    text-transform: none;
    text-decoration: none !important;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 10px;
    box-shadow: none;
    color: $grey;
    font-family: $body-font;
    font-weight: 400;
    font-size: 0.9rem;
    &.clear {
        border-radius: 3px;
        border: 2px solid $white;
        background: transparent;
        padding: 9px 10px;
        &:hover {
            background: $white;
            color: $brown-grey !important;
        }
    }
    &.full-width {
        width: 100%;
    }
    &.btn-green{
        background: $green;
        width: auto;
        padding: 12px 20px;
        font-size: 16px;
    }
}

.btn-panel {
    border-radius: 30px;
    white-space: normal;
    line-height: 1.2em;
    padding: 12px 15px;
    p {
        font-weight: normal;
    }
}

.btn-block {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
