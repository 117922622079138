.story-card-buttons {
    a, .main-button {
        color: darken($light-grey, 25%);
        font-weight: normal;
        position: relative;
        padding-right: 25px;
        margin-right: 15px;
        height: 100%;
        &:after {
            content: "";
            position: absolute;
            top: -10px;
            right: 0;
            height: 40px;
            width: 1px;
            border-left: 1px solid lighten($grey, 25%);
        }
        &:last-of-type {
            &:after {
                display: none;
            }
        }
        &:Hover {
            color: darken($grey, 20%);
            text-decoration: none;
        }
        &.liked {
            color: #F44336;
        }
    }
}