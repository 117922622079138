#register-page{
    h1{
        margin-bottom: 0;
    }
    h3{
        margin-bottom: 20px;
    }

    form{
        .note{
            a{
                color: $white;
                font-size: 14px;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}