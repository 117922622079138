#page-home{
    .categories-navigation{
        display: block;
    }
    .main-content{
        text-align: center;
        display: block;
        margin:0 auto;
        margin-top: 200px;
        @media only screen and (max-width: 992px) {
            margin-top: 0;
            margin-bottom: 50px;
        }
        .btn.start-here{
            display: block;
            margin:0 auto;
            width: 100%;
            font-size: 1.2rem;
            max-width: 250px;
        }

        .wrapper{
            &.has-card{
                    margin-top:-30px;
                    .text{
                        width: 55%;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        h1{
                            font-weight: 500;
                            color:$white;
                            margin-bottom: 50px;
                            @media only screen and (max-width: 992px) {
                                margin-bottom: 10px;
                            }
                        }
                        .button{
                            padding: .4em 3.5em;
                        }
                    }
                    .card-container{
                        width: 42%;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        .card{
                            text-align: left;
                        }
                    }
            }
        }
    }
}
