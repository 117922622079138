.navigation-links{
    background: white;
    margin-left: auto;
    margin-right: 40px;
    height: 50px;
    border-radius: 0 0 20px 20px;
    padding:10px;
    min-width: 100px;
    color: $grey;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    width: 275px;
    a {
        color: $grey;
        font-weight: 400;
        &:first-of-type {
            border-right: 2px solid $grey;
            margin-right: 10px;
            padding-right: 10px;
        }
    }

    @media only screen and (max-width: 992px) {
        display: inline-block;
        float: right;
    }

}
