.sidebar-todays-card{
    
    .paper-stick{
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url($stickyTapeImage);
        padding-top: 41px;
        padding-bottom: 20px;
        margin-left: -20px;
        padding-left: 90px;
        filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, .4));
        display: block;
        margin-bottom: 25px;
        h5{
            color:$brown-grey;
            font-weight: 500;
            margin-bottom: 10px;
            line-height: 26px;
            width: calc(100% - 5px);
        }
        .btn{
            color: $white !important;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}