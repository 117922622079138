$colours:(
    'white': $white,
    'black': $black,
    'dark-green': $dark-green,
    'green': $green,
    'orange': $orange,
    'yellow': $yellow,
    'pink': $pink,
    'dark-blue': $dark-blue,
    'light-blue': $light-blue,
    'purple': $purple,
    'grey': $grey,
    'light-grey': $light-grey,
    'off-white': $off-white,
    'cream': $cream,
    'before-people': $dark-green,
    'first-settlers': $orange,
    'romans': $light-blue,
    'saxons-vikings-normans': $yellow,
    'medieval': $pink,
    'tudors-stuarts': $dark-blue,
    'markets-to-railways': $purple,
    'brick-town-to-new-town': $green,
);

@each $name, $colour in $colours {
    .background-#{$name}{
        background-color: $colour !important;
    }
    .text-#{$name}{
        color: $colour !important;
    }
}