.overlay.add-story{
    h2{
        font-size: 50px;
        margin: 100px 0 80px;
    }
    .link-blocks{
        img{
            max-width:60%;
            height:auto;
            margin-top:40px;
        }
        h3{
            font-size:35px;
        }
        .col{
            padding-top:35px;
        }
        .sep{
            span{
                position:relative;
                padding-top:40px;
                display:block;
                height:100%;
                text-align:center;
                font-size:35px;
                font-weight:400;
                &:before{
                    content:'';
                    position: absolute;
                    top:0;
                    height:30px;
                    width:3px;
                    background:$green;
                    left:50%;
                }
                &:after{
                    content:'';
                    position: absolute;
                    bottom:0;
                    height:calc(100% - 85px);
                    width:3px;
                    background:$green;
                    left:50%;
                }
            }
        }
    }
}