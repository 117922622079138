#category-single, #story-single{
    .categories-navigation{
        display: block;
        margin-top: 60px;
    }
}

#category-single{
    body .main-content{
        overflow: hidden;
    }
}