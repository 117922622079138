.story-overlay-title{
    display: block;
    width: calc(100% - 50px);
    h1{
        display: inline-block;
        max-width: 100%;
        word-wrap: break-word;
        margin-right: 20px;
    }
    .other{
        margin-top:10px;
        margin-bottom: 10px;
        display: inline-block;
        width: auto;
        vertical-align: top;
        height: 30px;
        padding-right: 20px;
        line-height: 30px;
        color: $white;
        a{
            color:$white;
            font-weight: normal;
            position: relative;
            padding-right: 15px;
            margin-right: 15px;
            height: 100%;
            &:after{
                content:"";
                position: absolute;
                top:-10px;
                right:0;
                height: 40px;
                width: 1px;
                border-left: 1px solid $white;
            }
            &:last-of-type{
                &:after{
                    display: none;
                }
            }
            &:Hover{
                color:$green;
                text-decoration: none;
            }
            &.liked{
                color:#F44336;
            }
        }
        i{
            font-size: 1.5em;
            display: inline-block;
            vertical-align: middle;
        }
        span{
            margin-left: 10px;
            font-size: 11pt;
        }
    }
}