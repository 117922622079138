.story.card {
    width: 350px;
    background: $white;
    border-radius: 1.2em;
    overflow: hidden;
    display: inline-block;
    margin: 0 auto;
    vertical-align: top;
    padding-bottom: 50px;
    position: relative;
    opacity: 0.1;
    cursor: pointer;

    animation: 2s ease 0s normal forwards 1 fadein;

    @media only screen and (max-width: 992px) {
        width: 280px;
        animation: none;
        opacity: 1;
    }

    &.add{
        height: 420px;
        cursor: pointer;
        i{
            font-size: 108px;
            color: $grey;
            opacity:0.2;
            border: 8px dashed;
            border-radius: 50%;
            height: 180px;
            width: 180px;
            text-align: center;
            line-height: 165px;
            display:block;
            margin:120px auto 0;
            font-style:normal;
        }
    }

}

@keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}
