.story-card-content{
    padding:20px;
    color: $grey;
    h3{
        font-size: 24px;
        color: $green;
        margin-bottom: 1rem;
        line-height: 1.2;
        word-wrap: break-word;
    }
    a{
        color:$green;
    }
    p{
        color:$grey;
        margin-bottom: 0;
        word-wrap: break-word;
    }

    @media only screen and (max-width: 992px) {
        padding: 10px;
        h3{
            font-size: 16px;
            margin-bottom: 5px;
        }
        p{
            font-size: 14px;
            line-height: 18px;
        }
    }

}

.story.card {
    @each $name, $colour in $colours {
        &.colour-#{$name} {
            .story-card-content {
                h3, a {
                    color: $colour;
                }
            }
        }
    }
}
