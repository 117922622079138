.categories-navigation{
    display: none;
    ul{
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 0;
        &:before{
            content: "";
            position: absolute;
            top: 45px;
            width: calc(100% - 200px);
            left: 100px;
            z-index: 0;
            height: 10px;
            background: #fff;
            @media only screen and (max-width: 1024px) {
                width: calc(100% - 100px);
                left: 50px;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        display: none !important;
    }

}
