.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba($black, 0.75);
    z-index: 9999;
    padding: 50px;
    transform: translateY(-100%);
    opacity: 0;
    transition: all $ease;
    overflow: hidden;
    &.open{
        transform: translateY(0);
        opacity: 1;
        @media only screen and (max-width: 992px) {
            overflow: hidden;
        }
    }
    @media only screen and (max-width: 992px) {
        padding: 0px;
        width: 100%;
        height: 100%;
    }
}
