body{
    background-color:$black;
    transition: none;
    position:relative;
    .main-body{
        position: relative;
        width: 100%;
        color:$white;
        display: block;
        margin:0 auto;
        width: calc(100% - 500px);
        margin-left: 250px;
        transition:all $ease;
        height: 100vh;
        &.sidebar-open{
            margin-left: 350px;
        }
        @media only screen and (max-width: 1300px) {
            width: calc(100% - 200px);
            margin-left: 100px;
        }
        @media only screen and (max-width: 992px) {
            width: 100%;
            margin-left: 0;
            padding-bottom: 100px;
            padding-left: 50px;
            padding-right: 10px;
        }
    }
}

.main-content{
    margin-top: 100px;
    padding-left: 30px;
    padding-right: 30px;
    @media only screen and (max-width: 992px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 100px;
        margin-top:40px;
    }
}
