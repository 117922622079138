$colours: (
  dark-pink: #b83176,
  pink: #e95197,
  orange: #ec6b1f,
  navy: #2e428c,
  cyan: #3ebee5,
  purple: #af2785,
  off-white: #eee,
  white: #fff,
  grey: #777,
  light-grey: #a2a2a2,
  off-grey: #f6f6f6,
  green: #3ab18e,
  off-black: #282828,
    cream: #d4c3a3
);

/* Theme Brand Colours */
$white: #FFFFFF;
$black: #000000;
$dark-green: #188d5f;
$green: #77b957;
$orange: #de6d3d;
$yellow: #f8c453;
$pink: #d44e8f;
$dark-blue: #1f778e;
$light-blue: #48c0de;
$purple: #5a528f;
$grey: #76787a;
$light-grey: #F1F1F2;
$off-white: #e7e7e7;
$body-copy: $white;
$red: #e36e39;
$brown-grey: #6D6E71;
$cream: #d4c3a3;

$background-image: "../images/background.png";
$stickyTapeImage: "../images/sidebar/taped-paper.png";

/* EM Converter */

$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

/* Breakpoints */

$mobile-sm: 320px;
$mobile: 375px;
$mobile-lg: 500px;
$mobile-xl: 600px;
$tablet-sm: 767px;
$tablet: 992px;
$desktop-sm: 900px;
$bootstrap-md: 991px;
$desktop: 1024px;
$desktop-lg: 1200px;

/* Fonts */

$coheadline: "co-headline";
$cotext: "co-text";

@import url('https://fonts.googleapis.com/css?family=Rubik:100,100i,200,200i,300,300i,400,400i,500,500i,700,700i');
$body-font: 'Rubik', sans-serif;
$header-font: "co-text", sans-serif;

//Transitions
$ease: ease 0.25s;


/* Breakpoint mixin */

@mixin breakpoint($breakPointWidth, $min-max: max) {
  @media screen and (#{$min-max}-width: $breakPointWidth) {
    @content;
  }
}










@media only screen and (max-width: 1200px) {
    ul.time-bar span.first-year, ul.time-bar span.last-year {
        font-size: 10pt;
        line-height: 16px;
        width: 50px;
    }

    ul.time-bar .blocks-percent {
        left: 100px;
    }

    ul.time-bar li {
        width: 12px;
    }
}

@media only screen and (max-width: 1300px) {
    .flipster .flipster__button{
        background:$white;
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 5px;
        &:hover{
            background: $white !important;
        }
    }

}

@media only screen and (max-width: 1024px) {
    .login-container .inner {
        width: calc(100% - 200px);
    }

    ul.time-nav li.active{
        transform: scale(1);
    }

}

@media only screen and (max-width: 900px) {
    header .nav .inner .year-slider{
        width: 300px;
    }
}

@media only screen and (max-width: 880px) {
    .story-overlay .story-inner .content section{
        display: block;
        width: 100%;
        float: none;
    }
    .story-overlay .story-inner .content section.resources{
        padding-left: 0;
    }

    .login-overlay .login-inner .sections .left-section, .login-overlay .login-inner .sections .right-section{
        display: block;
        width: 100%;
        float: none;
    }

    .login-overlay .login-inner .sections .left-section p, .login-overlay .login-inner .sections .left-section ul,
    .login-overlay .login-inner .sections .left-section .list-of-features,
    .login-overlay .login-inner .sections .right-section form input{
        width: 100%;
    }

    header form .tags-container{
        width: calc((100%/3) - 20px);
    }

    header form .tags-container:nth-of-type(5n+5){
        margin-right: 20px;
    }

    header form .tags-container:nth-of-type(3n+3){
        margin-right: 0;
    }

}

@media only screen and (max-width: 850px) {
    ul.time-bar span.first-year, ul.time-bar span.last-year{
        display: none;
    }
    ul.time-bar .blocks-percent{
        left: 0;
        width: 100%;
    }
}


.reponsive-overlay{
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background:$black;
    z-index:99999999999;
    display: none;
    .content{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        h4{
            margin-top:30px;
            color: $white;
        }
    }
}


.mobile-time-nav{
    display: none;
}

@media only screen and (max-width: 992px){
    ul.time-nav, ul.time-bar, header aside.sidemenu, header .close-menu, .login-container{
        display: none;
    }

    header .nav .inner .year-slider span.cat-title{
        top:95px;
        font-size: 12pt;
    }

    body.page-template-home .explore-container .text h1{
        font-size: 30px;
        line-height: 28px;
    }

    body.page-template-home .explore-container .text .button{
        padding: 10px;
    }

    body.page-template-home .explore-container .card-container{
        .card{
            width: 280px;
        }
    }

    body.page-template-home .explore-container .text{
        width: 45%;
    }

    body.blur header{
        width: 0;
    }

    //
    //body.blur{
    //    header{
    //        display: none;
    //    }
    //}



    .mobile-time-nav{
        display: block;
        background: lighten($grey, 45);
        width: 100%;
        word-wrap: normal;
        position: fixed;
        bottom: 0;
        left: 0;
        padding-top:35px;
        transform: translateY(100%);
        transition:all ease 0.5s;
        z-index:9999999;



        .nav-container{
            width: 100%;
            overflow-y: auto;
        }

        &.open{
            transform: translateY(0);
            box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.23);
        }

        .mobile-time-toggle{
            position: absolute;
            bottom: 100%;
            background: lighten($grey, 45);
            right: 50px;
            width: 140px;
            height: 31px;
            text-align: center;
            border-radius: 100% 100% 0 0;;
        }

        ul.time-nav{
            display: flex;
            width: 150%;
            height: 135px;
            margin-top:15px;
            li{
                z-index:2;
                span{
                    color: $grey;
                }
            }
            &:after{
                z-index: 1;
            }
        }
    }

    header .nav .inner .year-slider{
        width: 200px;
    }

    header{
        margin-top:0;
    }

    header .reversed-logo{
        width: 100px;
        top: 10px;
        left: 10px;
    }

    body article{
        width: 100%;
    }

    header .nav .inner{
        width: calc(100% - 20px);
    }


    header {
        .nav {
            .inner {
                .nav-button {
                    &.mobile-only {
                        display: inline-block;
                        float: right;
                        magin-right: 40px;
                    }
                }
            }
        }
    }


    .story-overlay .story-inner, .search-overlay .search-inner{
        width: calc(100% - 50px);
        padding-top:30px;
        .main-body{
            width: 100%;
            margin-left: 0;
        }
    }

    .story-overlay .story-inner .close, .search-overlay .close-search{
        top: 0;
        right: -10px;
    }

    .flipster__button{
        background:$white;
        border-radius: 10px 0 0 10px;
        padding-left: 5px;
        padding-top: 5px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        &.flipster__button--prev{
            border-radius:  0 10px 10px 0;
            padding-left: 0;
            padding-right: 5px;
        }
        &:focus, &:hover{
            background: $white;
        }
    }

    header .nav{
        margin-bottom: 35px;
    }

    //header .nav .inner .year-slider span.cat-title{
    //    top:75px;
    //}

    footer#footer{
        display: block;
    }

    .card{
        width: 300px;

        &.daily-card{
            .card-image{
                height: 200px;
            }
        }

    }

    .card.search-card .mag{
        left: 50px;
    }

    .card .card-image{
        height: 150px;
    }

    .card .card-content h3{
        font-size: 20px;
        margin-bottom: 5px;
    }

    .card .card-content p{
        font-size: 14px;
        line-height: 1.4;
    }

    header .nav .inner .nav-button.search.refine{
        margin-top:10px;
    }

    header form .tags-container h5{
        font-size: 14px;
    }

    .custom-check+label span{
        font-size: 13px;
    }


    header form .tags-container{
        width: calc((100%/2) - 20px);
    }

    header form .tags-container:nth-of-type(3n+3){
        margin-right: 20px;
    }

    header form .tags-container:nth-of-type(2n+2){
        margin-right: 20px;
    }

}

@media only screen and (max-width: 1300px) {

}


@media only screen and (orientation: portrait) {
    header{
        display: none;
    }
    body article{
        display: none;
    }
    .reponsive-overlay{
        display: block;
    }

    header .nav .inner .nav-button.search.refine{
        margin-top:10px;
    }


}



@-moz-document url-prefix() {
    header aside.sidemenu{
        left: -200px;
    }

    header .reversed-logo{
        top:10px;
    }

    .login-container{
        z-index:999999;
    }

    @media (min-width: 1300px){
        body.menu-open {
            padding-left: 200px;
        }
    }

}


.flipster{
    overflow-y: hidden;
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    ul.time-nav:after{
        width: calc(100% - 85px);
    }
    article.menu-open{
        .flipster__button{
            z-index:0;
        }
    }
}

body.blur{
    .login-container{
        z-index: 2;
    }
}
