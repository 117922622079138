.quiz-card-incorrect-overlay{
    height: 0;
    overflow: hidden;
    .header{
        background:$red;
        padding:20px;
        color:$white;
        i.fa{
            font-size: 75px;
            display: inline-block;
            vertical-align: bottom;
        }
        h3{
            display: inline-block;
            color:$white;
        }
    }
    .answers{
        padding-top:0;
    }
    .card-content{
        color: $grey;
        p{
            &:first-of-type{
                padding: 20px;
                margin-bottom: 0;
            }
        }
    }
}