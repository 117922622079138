ul.time-bar{
    list-style-type: none;
    height: 25px;
    width: auto;
    display: table;
    margin:0 auto;
    position: relative;
    margin-top:60px;
    margin-bottom: 50px;
    @each $name, $colour in $colours {
        &.colour-#{$name}{
            li{
                background: $colour;
            }
            .indicator:after{
                color:$colour;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        padding-left: 5px;
        width: calc(100% - 10px);
    }

    @media only screen and (max-width: 992px) {
        display: none;
    }
}
