.quiz-card-answer-item{
    padding-left: 20px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    &:last-of-type{
        margin-bottom: 0;
    }
    label{
        margin-bottom: 0;
        span{
            color:$grey;
            font-weight: normal;
            margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
        }
        input[type=radio]{
            position: absolute;
            visibility: hidden;
            &:checked ~ .check {
                border: 2px solid $green;
                background: $green;
            }
        }
        .check{
            display: block;
            position: absolute;
            border: 1px solid $green;
            border-radius: 100%;
            height: 17px;
            width: 17px;
            top: 50%;
            left: 0;
            z-index: 5;
            transform: translateY(-50%);
            transition: all ease 0.25s;
        }
    }
    &:last-of-type{
        margin-bottom: 0;
    }
    &:hover{
        .check{
            border: 2px solid $green;
        }
    }
}