.story-overlay-resources{
    .files{
        max-height:245px;
        overflow: auto;
        margin-bottom: 25px;
        .videos{
            display: block;
            width: 100%;
        }
        .video-container{
            display: inline-block;
            width: calc(50% - 20px);
            position: relative;
            a.play-link{
                position: absolute;
                top:50%;
                left: 50%;
                color:$white;
                font-size: 35px;
                z-index: 999;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        video{
            width: 100%;
            border-radius: 3px;
            margin-right: 10px;
        }
    }
}