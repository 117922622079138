aside.sidebar {
    position: fixed;
    left: 0;
    width: 300px;
    background-color: rgba(233, 229, 214, 1);
    height: 100%;
    background-image: url("../images/sidebar/background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $body-font;
    max-height: 100vw;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transform: translateX(-100%);
    transition: all $ease;
    box-shadow: 0px 0px 0px rgba($black, 0);
    will-change: transform;
    z-index: 9999;
    .inner{
        overflow: scroll;
        height: 100%;
    }
    @media only screen and (max-width: 992px) {
        display: block;
        .inner {
            overflow: scroll;
        }
    }

    .secondary-logo{
        content: "";
        position: absolute;
        top: 25px;
        left: calc(100% + 25px);
        width: 150px;
        height: 128px;
        background-image: url("../images/sidebar/logo-reversed.svg");
        background-size: cover;
        background-repeat: no-repeat;
        transition: all $ease;
        z-index: 2;
        @media only screen and (max-width: 1300px) {
            width: 100px;
            height: 100px;
        }
        @media only screen and (max-width: 992px) {
            display: none;
        }
    }

    &.open {
        transform: translateX(0);
        box-shadow: 0px 0px 10px $black;
        .secondary-logo{
            opacity: 0;
            left: -150px;
            z-index: -1;
        }
    }

    .main{
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding: 25px;
    }
    .bottom-drawer {
        ul {
            margin-left: 0;
            padding-left: 0;
        }
        //position: absolute;
        //bottom: 0;
        //left: 0;
        width: 100%;
        @media only screen and (max-width: 992px) {
            position: static;
        }
    }

}
