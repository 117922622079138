#public-dashboard,
#teacher-dashboard{
    .link-blocks{
        margin-top:120px;
        img{
            width: auto;
            height: 150px;
            display: block;
            margin:0 auto;
            margin-bottom: 30px;
        }
    }

    .premium-only{
        opacity: 0.3;
        img, a, h3{
            opacity: 0.3;
        }
    }

    .bottom-links{
        position: absolute;
        bottom: 20px;
        right: 0;
        @media only screen and (max-width: 992px) {
            position: static;
            margin-bottom: 75px;
        }
        .bottom-link{
            width: 150px;
            text-align: center;
            &.wider{
                width: 250px;
                padding-top:30px;
            }
            &.border-right{
                border-right: 2px solid $white;
            }
            .image-container{
                width: 100%;
                height: 70px;
                position: relative;
                img{
                    width: auto;
                    height: 50px;
                    max-width: 75px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
            h6{
                font-weight: normal;
                line-height: 1.3;
                margin-bottom: 0;
            }
        }
    }
    @media only screen and (max-width: 992px) {
        .main-content{
            margin-top: 0 !important;
            padding-left: 30px;
            padding-right: 30px;
        }
        .link-blocks{
            margin-top: 20px !important;
            img{
                height: 50px !important;
            }
            h3{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

#teacher-dashboard{
    .link-blocks{
        img{
            height: 120px;
        }
    }
}
