body {
  font-size: 16px;
  color: $grey;
  line-height: 1.8em;
  font-family: $body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-family: $body-font;
  margin-bottom: 0.5em;
}

h1{
    font-size: 50px;
    line-height: 48px;
    font-weight:500;
}
h2{
    font-size: 32px;
    line-height: 31px;
    font-weight:500;
}
h3{
    font-size: 26px;
    line-height: 35px;
    font-weight:500;
}
h4{
    font-size: 24px;
    line-height: 35px;
    font-weight:bold;
}
h5{
    font-size: 20px;
    line-height: 35px;
    font-weight:bold;
}
h6{
    font-size: 16px;
    line-height: 35px;
    font-weight:bold;
}



@include breakpoint(992px) {
  h1 {
    font-size: 32px;
      line-height: 34px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 28px;
  }
}

p {
  margin-bottom: 15px;
}
a {
  color: $green;
}
a:hover {
  text-decoration: none;
}
strong, b {
  font-weight: bold;
}

.disable-external-link-icon {
  padding-right: 0 !important;
  &:after {
    display: none !important;
  }
}

.pagination a {
  padding-right: 0 !important;
  &:after {
    display: none !important;
  }
}
