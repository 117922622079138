.timeline-list{
    float:left;
    width:100%;
    padding-bottom:50px;
    .timeline{
        position: relative;
        height: 420px;
        width: 450px;
        float: left;
        padding-left:50px;
        margin-bottom: 50px;
        .timeline-link{
            position: absolute;
            top: 0;
            left: 0;
            height: 110%;
            width: 100%;
            z-index: 10;
            @media only screen and (max-width: 1024px) {
                width: 85%w;
                height: 100%;
            }
            h3{
                position: absolute;
                bottom:0;
                text-align: center;
                width:100%;
                @media only screen and (max-width: 1024px) {
                    font-size: 20px;
                }
            }
            &:hover{
                color: $dark-green;
            }
        }
        li{
            list-style:none;
            position: absolute;
            z-index: 2;
            &:nth-child(1){
                transform: scale(0.8);
                z-index: 3;
            }
            &:nth-child(2){
                transform:rotate(10deg) translate(60px, -20px) scale(0.7);
            }
            &:nth-child(3){
                transform:rotate(-10deg) translate(-60px, -20px) scale(0.7);
            }
        }
    }
}

.btn.create-timeline{
    background: $green;
    float: right;
    width: auto;
    padding: 12px 20px;
    font-size: 18px;
    color:$white;
}