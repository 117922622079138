.story-card-buttons{
    width: 100%;
    background: $light-grey;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 50px;
    color: $grey;
    position: absolute;
    bottom:0;
    left: 0;
    i{
        font-size: 2em;
        display: inline-block;
        vertical-align: middle;
    }
    span{
        margin-left: 10px;
        font-size: 11pt;
    }
}