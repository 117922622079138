.sidebar-bottom-links{
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    li{
        padding-right: 5px;
        margin-right: 5px;
        display: inline-block;
        border-right: 1px solid $grey;
        font-size: 0.9rem;
        &:last-of-type{
            border-right: 0;
        }
        a{
            color: $grey;
        }
    }
}