.share-button{
    position: relative;
    padding-right: 25px;
    display: inline-block;
    margin-right: 15px;
    color: white;
    .main-button{
        cursor: pointer;
    }
    a{
        margin-right: 7px;
    }
    &:after{
        content:"";
        position: absolute;
        top:0px;
        right:0;
        height: 30px;
        width: 1px;
        border-left: 1px solid $white;
    }
    .share-box{
        display: inline-block;
        position: absolute;
        top: calc(100% + 15px);
        left: 0;
        width: 150px;
        height: auto;
        background:$white;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        padding-top:10px;
        padding-bottom: 10px;
        transform: scale(0);
        transform-origin: top left;
        transition: all ease 0.15s;
        color:darken($light-grey, 25%);
        &.open{
            transform: scale(1);
        }
        &:hover{
            color:darken($light-grey, 25%);
        }
        .share-link{
            display: block;
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding-left: 20px;
            padding-right:20px;
            cursor: pointer;
            i.fab{
                font-size: 20px;
                transform: translateY(-1px);
                margin-right: 10px;

            }
            &:hover{
                color: $white;
            }
            &.fb{
                &:hover{
                    background: #3b5998;
                }
            }
            &.tw{
                &:hover{
                    background: #00aced;
                }
            }
            &.gp{
                &:hover{
                    background: #dd4b39;
                }
            }
        }
    }
}